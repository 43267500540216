import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

// imports: syklone
import { Box, Grid, styled } from "syklone/ui/index.js";

// imports: local
import { WidgetScheduleNavigation, WidgetTitle } from "../../widgets/index.js";

const Nav = (title, page) => {
  return {
    title: title,
    menuItems: [
      {
        name: "Manual schedule",
        navTo: `/dashboard/${page}/manual`,
      },
      {
        name: "Priority schedule",
        navTo: `/dashboard/${page}/priority`,
      },
      {
        name: "Auto schedule",
        navTo: `/dashboard/${page}/auto`,
      },
      {
        name: "Distributed",
        navTo: `/dashboard/${page}/distributed`,
      },
    ],
  };
};

let nav = Nav("Schedule type", "scheduling");

// -------- Styled components ---------
const PageWrapper = styled(Box)({
  padding: "20px 25px",
});

const CustomBox = styled(Box)(() => ({
  width: "100%",
  padding: "25px 30px 30px 30px",
}));

const ProductBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#000" : "#f8f8f8",
  borderRadius: "6px",
  marginTop: "22px",
}));

function PageScheduling(props) {
  return (
    <>
      <CustomBox>
        <Grid container justifyContent="space-between" sx={{ alignItems: "center" }}>
          <Grid item>
            <WidgetTitle title="Scheduling" isBackButton={true} backRoute="/dashboard" />
          </Grid>
        </Grid>
        <ProductBox>
          <WidgetScheduleNavigation location={props.location} match={props.match} data={nav} />
          <PageWrapper>
            <Outlet />
          </PageWrapper>
        </ProductBox>
      </CustomBox>
    </>
  );
}

PageScheduling.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.func,
};

export default PageScheduling;
