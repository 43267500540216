import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, FormControl, FormHelperText, TextField } from "syklone/ui/index.js";
import { useElectronicSignature, SIGNATURE_STATES } from "syklone/components/hooks/index.js";
import { AzureAuthButton } from "syklone/api/react/index.js";

// imports: local
import { DialogNotification } from "../../dialogs/index.js";

const WidgetElectronicSignature = React.forwardRef(({ values, setFieldValue, handleChange }, ref) => {
  const { data, signatureStatus, initiateSignatureRedirect, resetSignatureStatus } = useElectronicSignature();
  const [hasSignatureError, setHasSignatureError] = React.useState(false);
  let signatureMessage = null;
  switch (signatureStatus) {
    case SIGNATURE_STATES.IN_PROGRESS:
      signatureMessage = {
        message: "Signature process started. Please complete the signature in the new tab.",
        severity: "info",
      };
      break;
    case SIGNATURE_STATES.COMPLETED:
      signatureMessage = { message: "Signature successfully verified.", severity: "success" };
      break;
    case SIGNATURE_STATES.MISSING:
      signatureMessage = { message: "Signature verification missing. Please try again.", severity: "error" };
      break;
    case SIGNATURE_STATES.FAILED:
      signatureMessage = { message: "Signature verification failed. Please try again.", severity: "error" };
      break;
    default:
      signatureMessage = null;
  }

  const setValues = React.useCallback(
    (username, userSigned) => {
      if (setFieldValue) {
        setFieldValue("username", username, false);
        setFieldValue("userSigned", userSigned, false);
      }
    },
    [setFieldValue]
  );

  React.useImperativeHandle(ref, () => ({
    setSignatureError: (errorState) => {
      setHasSignatureError(errorState);
    },
    resetSignature: () => {
      if (signatureStatus === SIGNATURE_STATES.COMPLETED) {
        resetSignatureStatus();
      }
      setValues("", "");
    },
  }));

  React.useEffect(() => {
    switch (signatureStatus) {
      case SIGNATURE_STATES.COMPLETED:
        if (data) {
          setValues(data.username, data.userSigned);
          setHasSignatureError(false);
        }
        break;
      case SIGNATURE_STATES.MISSING:
      case SIGNATURE_STATES.FAILED:
        setValues("", "");
        setHasSignatureError(true);
        break;
      default:
        // Optionally handle any other cases or do nothing
        break;
    }
  }, [signatureStatus, data, setValues]);

  React.useEffect(() => {
    return () => {
      setHasSignatureError(false);
    };
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <DialogNotification alert={signatureMessage} style={{ marginBottom: "1em" }} />
      {signatureStatus !== SIGNATURE_STATES.COMPLETED && (
        <>
          <Box sx={{ width: "100%", marginBottom: "1em" }}>
            <AzureAuthButton
              onClick={() => {
                setValues("", "");
                if (signatureStatus === SIGNATURE_STATES.FAILED) {
                  resetSignatureStatus();
                } else {
                  initiateSignatureRedirect();
                }
              }}
              text={
                signatureStatus === SIGNATURE_STATES.FAILED ||
                signatureStatus === SIGNATURE_STATES.MISSING ||
                signatureStatus === SIGNATURE_STATES.IN_PROGRESS
                  ? "Retry Electronic Signature"
                  : "Sign Electronically"
              }
              dataTag="electronic-signature-button"
            />

            {hasSignatureError && (
              <FormHelperText sx={{ textAlign: "center", display: "flex", marginTop: "1em", fontSize: "12px" }} error>
                {"Please complete the electronic signature process to proceed."}
              </FormHelperText>
            )}
          </Box>
        </>
      )}
      <FormControl required fullWidth>
        <TextField
          name="userComment"
          label="Signature Comment"
          variant="outlined"
          value={values.userComment}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth>
        <input type="hidden" name="username" value={values.username} />
        <input type="hidden" name="userSigned" value={values.userSigned ? "true" : "false"} />
      </FormControl>
    </Box>
  );
});

WidgetElectronicSignature.displayName = "WidgetElectronicSignature";

WidgetElectronicSignature.propTypes = {
  values: PropTypes.shape({
    username: PropTypes.string,
    userSigned: PropTypes.bool,
    userComment: PropTypes.string,
  }),
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
};

export default WidgetElectronicSignature;
