import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useOutletContext, useNavigate } from "react-router-dom";

// imports: syklone
import { diff } from "syklone/libraries/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import { Box, DataGrid, Grid, icons, IconButton, styled, TextField, Typography } from "syklone/ui/index.js";

// imports: local
import { WidgetPopoverMenu } from "../../widgets/index.js";
import { BuildFilesFetcher } from "../../core/index.js";
import { DialogBuildHistory } from "../../dialogs/index.js";

const CustomGrid = styled(Grid)(({ theme }) => ({
  height: 655,
  padding: "10px 15px",
}));

const BoxFamiliyTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  paddingLeft: 18,
  paddingBottom: 13,
  paddingTop: 13,
  borderBottom: `1px solid ${theme.palette.mode === "dark" ? "#212121" : "#dadada"}`,
}));

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        position: "absolute",
        right: 0,
        top: -61,
      }}
    >
      <TextField
        variant="outlined"
        size="small"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        disabled={props.disabled}
        data-syklone="table-filter"
        InputProps={{
          startAdornment: <icons.mui.Search fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <icons.mui.Cancel fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: 300,

          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderLeft: "1px solid #212121",
              borderBottom: "none",
              borderTop: "none",
              borderRight: "none",
              borderRadius: 0,
            },
          },

          "& .MuiInputBase-root": {
            padding: "7px 10px",
            "& > .MuiSvgIcon-root": {
              mr: 0.5,
            },
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const valueFormater = (value) => {
  const notAvailable = "N/A";

  if (value === "" || value === undefined) {
    return notAvailable;
  }

  if (typeof value === "boolean") {
    return value.toString();
  }

  if (Array.isArray(value)) {
    if (value.length === 0 || (value.length > 0 && typeof value[0] === "string" && value[0].length === 0)) {
      return notAvailable;
    }

    return JSON.stringify(value)
      .replace(/]|[[]|{|}|"|"/g, "")
      .split(",")
      .join(", ");
  }

  if (typeof value === "object" && value !== null && Object.keys(value).length === 0) {
    return notAvailable;
  }

  if (typeof value === "number" || (typeof value === "string" && value.length > 0)) {
    return JSON.stringify(value)
      .replace(/]|[[]|{|}|"|"/g, "")
      .split(",")
      .join(", ");
  }

  return JSON.stringify(value)
    .replace(/]|[[]|{|}|"|"/g, "")
    .split(",")
    .join(", ");
};

function PageTableDataBuildFiles({ setters }) {
  const [data, getAuditTrailHistory, isBuildFileLoading] = useOutletContext();
  const navigate = useNavigate();
  const apiInstance = useApiContext();

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState(data ? data.dataPageProductDetails.buildFiles : []);
  const [filteredTableData, setFilteredTableData] = useState(data ? data.dataPageProductDetails.buildFiles : []);
  const [isPlatformAvailable, setIsPlatformAvailable] = useState({});

  // Build history dialog state
  const [dialogBuildHistoryData, setDialogBuildHistoryData] = useState([]);
  const [dialogBuildHistoryTitle, setDialogBuildHistoryTitle] = useState("");
  const [isDialogBuildHistoryOpen, setIsDialogBuildHistoryOpen] = useState(false);

  useEffect(() => {
    setTableData(data.dataPageProductDetails.buildFiles);
    setFilteredTableData(data.dataPageProductDetails.buildFiles);
  }, [data]);

  const checkIfPlatformsAreAvailable = async (buildFileId) => {
    let fetcher = new BuildFilesFetcher("RELEASED", apiInstance);
    let platformId = await fetcher.getPlatformIdFromBuildFile(buildFileId);

    return platformId ? true : false;
  };

  useEffect(() => {
    const updatePlatformAvailability = async () => {
      const buildFileIds = tableData.map((item) => item.buildFileId);

      const availability = {};
      for (const buildFileId of buildFileIds) {
        availability[buildFileId] = await checkIfPlatformsAreAvailable(buildFileId);
      }
      setIsPlatformAvailable(availability);
    };

    if (tableData && tableData.length > 0) {
      updatePlatformAvailability();
    }
  }, [tableData]);

  const actionMenu = (params) => {
    return {
      menuItems: [
        {
          name: "Parts",
          action: () =>
            navigate("/dashboard/part-platform/viewer/part-viewer", {
              state: {
                buildFileId: params.row.buildFileId,
                buildFileType: "RELEASED",
              },
            }),
        },
        {
          name: "Platforms",
          action: () =>
            navigate("/dashboard/part-platform/viewer/platform-viewer", {
              state: {
                buildFileId: params.row.buildFileId,
                buildFileType: "RELEASED",
              },
            }),
          disabled: !isPlatformAvailable[params.row.buildFileId],
        },
        {
          name: "Slice data",
          action: () => console.log("Slice data"),
        },
        {
          name: "Labels",
          action: () => console.log("Labels"),
        },
        {
          name: "Build file history",
          action: async () => await dialogBuildHistory(params.row.name, params.row.buildFileId),
        },
      ],
    };
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: "buildFileId",
      headerName: "ID",
      minWidth: 140,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      filterable: false,
      width: 170,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 70,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <WidgetPopoverMenu data={actionMenu(params)} />
          </div>
        );
      },
    },
  ];

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = filteredTableData.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setTableData(filteredRows);
  };

  const dialogBuildHistory = async (title, id) => {
    setIsLoading(true);
    let response = await getAuditTrailHistory(title);
    if (response?.data["status_code"] !== 403) {
      setIsDialogBuildHistoryOpen(true);
      setDialogBuildHistoryTitle(title);
      setDialogBuildHistoryData(() =>
        response.data.data.map((item) => {
          let oldVal = item.old_values;
          let newVal = item.new_values;
          let arr = Object.keys(oldVal).length !== 0 ? Object.keys(diff(oldVal, newVal)) : Object.keys(newVal);
          return {
            id: item.id,
            action: item.action,
            date: item.date,
            // TODO: Backend needs to return parameters "completedByName" and "completedByEmail" in response
            // completedByName: "Zvonimir",
            // completedByEmail: "zvonimir@syklone.world",
            oldVal: arr.map((item) => (
              <li key={item}>
                <span style={{ opacity: 0.6 }}>{item}</span>: {valueFormater(oldVal[item])}
              </li>
            )),
            newVal: arr.map((item) => (
              <li key={item}>
                <span style={{ opacity: 0.6 }}>{item}</span>: {valueFormater(newVal[item])}
              </li>
            )),
          };
        })
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DialogBuildHistory
        data={dialogBuildHistoryData}
        title={dialogBuildHistoryTitle}
        isOpen={isDialogBuildHistoryOpen}
        setIsOpen={setIsDialogBuildHistoryOpen}
        isLoading={isLoading}
      />
      <BoxFamiliyTitle>Build Files</BoxFamiliyTitle>
      <CustomGrid item xs={12} data-syklone="products-details-table-wrapper">
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          rows={tableData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id}
          loading={isBuildFileLoading}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
          sx={(theme) => ({
            fontSize: 13,
            "&.MuiDataGrid-root": {
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: theme.palette.mode === "dark" ? "#1F2527" : "#f8f8f8",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
            },
            ".MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
          })}
        />
      </CustomGrid>
    </>
  );
}

PageTableDataBuildFiles.propTypes = {
  setters: PropTypes.object,
};

export default PageTableDataBuildFiles;
