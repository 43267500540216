import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  Stack,
  TextField,
  Yup,
  useFormik,
  MenuItem,
  FormHelperText,
} from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

// imports: local
import { DialogResponseMessage } from "../index.js";
import { WidgetElectronicSignature } from "../../widgets/index.js";

const validationCreateUserSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  accountTypeId: Yup.string().required("Account type is required"),
  department: Yup.string().when("accountTypeId", {
    is: (val) => val === "1" || val === "2",
    then: (schema) => schema.required("Department is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  password: Yup.string().when("accountTypeId", {
    is: (val) => val === "2",
    then: (schema) => schema.required("Password is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  machineDefinition: Yup.string().when("accountTypeId", {
    is: (val) => val === "3",
    then: (schema) => schema.required("Machine definition is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const DialogUser = React.forwardRef(({ onDialogEditUser, onDialogCreateUser, optionsData }, ref) => {
  const widgetElectronicSignatureRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const dialogDataRef = React.useRef(null);
  const id = dialogDataRef.current?.id;
  const accountTypes = optionsData.accountTypes || [];
  const machineDefinitions = optionsData.accountTypes || [];

  const onSubmit = async (values) => {
    widgetElectronicSignatureRef.current?.setSignatureError(false);

    if (!values.username || !values.userSigned) {
      widgetElectronicSignatureRef.current?.setSignatureError(true);
      return; // Exit the onSubmit function early
    }
    const payload = {
      name: values.name,
      email: values.email,
      password: values.password,
      department: values.department,
      ["is_active"]: isEditMode ? dialogDataRef.current?.isActive : 1,
      ["is_admin"]: isEditMode ? dialogDataRef.current?.isAdmin : 0,
      ["account_type"]: values.accountTypeId,
      ["machine_definition_id"]: values.machineDefinition,
    };

    const modifiedPayload = {
      metadata: payload,
      signature: {
        ["user_signed"]: values.userSigned,
        ["user_signed_email"]: values.username,
        comment: values.userComment,
      },
    };
    if (!isEditMode) {
      onDialogCreateUser(modifiedPayload);
    } else {
      onDialogEditUser(id, modifiedPayload);
    }
  };

  const initialValues = {
    name: "",
    email: "",
    password: "",
    accountTypeId: "",
    department: "",
    machineDefinition: "",
  };

  React.useImperativeHandle(ref, () => ({
    setResponseMessage: (errorMessage) => {
      setResponseMessage(errorMessage);
    },
    setDialogData: (data) => {
      setIsEditMode(data ? true : false);
      dialogDataRef.current = data;
      console.log(dialogDataRef.current);
    },
    setIsDialogOpen: (value) => {
      setIsOpen(value);
    },
    setIsSubmitting: (value) => {
      setIsSubmitting(value);
    },
  }));

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationCreateUserSchema,
    onSubmit,
  });

  const accountType = formik?.values?.accountTypeId;
  const isMachineType = accountType === "3";
  const isServiceType = accountType === "2";
  const isUserType = accountType === "1";

  React.useEffect(() => {
    if (isOpen && isEditMode) {
      const { name, email, password, accountTypeId, department } = dialogDataRef.current ?? initialValues;
      formik.setValues({
        name,
        email,
        password,
        accountTypeId: accountTypeId ? accountTypeId : "",
        department,
      });
    } else {
      setResponseMessage(null);
      setIsSubmitting(false);
      widgetElectronicSignatureRef.current?.resetSignature();
      formik.resetForm();
    }
  }, [isEditMode, isOpen]);

  return (
    <DialogCommonBase
      title={isEditMode ? "Edit User" : "Create User"}
      showClose
      disableBackdropClick
      isOpen={isOpen}
      dataSyklone="admin-user-dialog"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={2}>
          <DialogResponseMessage message={responseMessage} widgetName="dialog-user" />
          <FormControl
            sx={{
              "& > :not(style)": { mt: 1, width: "450px" },
            }}
            noValidate
            autoComplete="off"
            data-syklone="dialog-user-email"
          >
            <TextField
              id="dialog-user-email"
              label="Email"
              name="email"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.email }}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </FormControl>

          <FormControl noValidate autoComplete="off" data-syklone="dialog-user-name">
            <TextField
              id="dialog-user-name"
              label="Name"
              name="name"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.name }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
          {!isEditMode && (
            <FormControl
              data-syklone="dialog-user-account-type"
              error={formik.touched.accountTypeId && Boolean(formik.errors.accountTypeId)}
            >
              <InputLabel id="dialog-user-account-type-label">Account Type</InputLabel>
              <Select
                labelId="dialog-user-account-type-label"
                id="dialog-user-account-type"
                value={formik.values.accountTypeId}
                label="Account type"
                name="accountTypeId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {accountTypes.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.touched.accountTypeId && formik.errors.accountTypeId && (
                <FormHelperText>{formik.errors.accountTypeId}</FormHelperText> // Display the error message
              )}
            </FormControl>
          )}
          {isServiceType && (
            <FormControl noValidate autoComplete="off" data-syklone="dialog-user-password">
              <TextField
                id="dialog-user-password"
                label="Password"
                type="password"
                name="password"
                variant="outlined"
                InputLabelProps={{ shrink: !!formik.values.password }}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </FormControl>
          )}
          {(isServiceType || isUserType) && (
            <FormControl noValidate autoComplete="off" data-syklone="dialog-user-department">
              <TextField
                id="dialog-user-department"
                label="Department"
                name="department"
                variant="outlined"
                InputLabelProps={{ shrink: !!formik.values.department }}
                value={formik.values.department}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.department && Boolean(formik.errors.department)}
                helperText={formik.touched.department && formik.errors.department}
              />
            </FormControl>
          )}

          {isMachineType && (
            <FormControl
              data-syklone="dialog-machine-definition"
              error={formik.touched.machineDefinition && Boolean(formik.errors.machineDefinition)}
            >
              <InputLabel id="dialog-machine-definition-label">Machine definition</InputLabel>
              <Select
                labelId="dialog-machine-definition-label"
                id="dialog-machine-definition"
                value={formik.values.machineDefinition}
                label="Machine definition"
                name="machineDefinition"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {machineDefinitions.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.touched.machineDefinition && formik.errors.machineDefinition && (
                <FormHelperText>{formik.errors.machineDefinition}</FormHelperText> // Display the error message
              )}
            </FormControl>
          )}
          <WidgetElectronicSignature
            ref={widgetElectronicSignatureRef}
            values={formik.values}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
          />
          <Button
            variant="contained"
            size="large"
            type="submit"
            data-syklone="submit-user-button"
            disabled={isSubmitting}
          >
            {isEditMode ? "Edit user" : "Create user"}
          </Button>
        </Stack>
      </form>
    </DialogCommonBase>
  );
});

DialogUser.displayName = "DialogUser";

DialogUser.propTypes = {
  onDialogEditUser: PropTypes.func,
  onDialogCreateUser: PropTypes.func,
  optionsData: PropTypes.shape({
    accountTypes: PropTypes.array.isRequired,
  }).isRequired,
};

export default DialogUser;
