import PropTypes from "prop-types";
import React, { useState } from "react";

// imports: syklone
import { CSS, useSortable } from "syklone/libraries/index.js";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  icons,
  IconButton,
  Popover,
  styled,
  Tooltip,
  Typography,
} from "syklone/ui/index.js";
import { usePermissionChecks } from "syklone/api/react/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

// imports: local
import { WidgetOverflowTip, WidgetScheduleCurrentBuild } from "../index.js";
import { DialogCommonConfirmation, DialogMoveToMachine } from "../../dialogs/index.js";
import { WidgetPopoverMenu } from "../widget_popover_menu/index.js";

const ScheduleItemContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#2f2f2f" : "#f8f8f8",
  minWidth: 190,
  padding: "9px",
  borderRadius: "3px",
  transition: "all 0.3s",
  "&:hover": {
    boxShadow: theme.palette.mode === "dark" ? "0px 6px 12px rgba(0, 0, 0, 0.5)" : "0px 4px 5px rgba(0, 0, 0, 0.2)",
  },
}));

const ScheduleItemType = styled(Grid)(({ theme }) => ({
  padding: "5px 8px",
  marginBottom: "5px",
  borderRadius: "3px",
  background: theme.palette.mode === "dark" ? "#000" : "#fff",
}));

const ScheduleItemTitle = styled("div")({
  fontWeight: "700",
});

const ScheduleItemSubtitle = styled("div")({
  opacity: 0.7,
});

const ScheduleItemTypography = styled(Typography)({
  fontSize: 13,
});

const StatusBox = styled(({ fontColor, type, ...rest }) => <Box {...rest} />)(({ theme, fontColor, type }) => {
  let color = "";
  switch (fontColor) {
    // case 0 when scheduling buildfiles on machine that is not quilified for production
    case 0:
      if (type !== "Build file") {
        color = theme.palette.warning.dark;
      } else {
        color = theme.palette.success.main;
      }
      break;
    case 1:
      color = theme.palette.success.main;
      break;
    case 2:
      color = theme.palette.warning.dark;
      break;
    case 3:
      color = theme.palette.error.main;
      break;
    default:
      color = theme.palette.text.primary;
  }
  return {
    color: color,
  };
});

const isQualifiedForProduction = (int) => {
  return int === 0 ? "False" : "True";
};

function WidgetScheduleRowItem({
  alert,
  setAlert,
  data,
  isDraggable,
  id,
  width,
  onClickItem,
  putUnscheduleBuildFile,
  machineValidity,
  putRescheduleToAnotherMachine,
  isRescheduleLoading,
  unlock,
  machineStatus,
  machineData,
}) {
  const { typeName, itemTitle, itemSubTitlePrefix, itemSubTitle, status, metadata, lock } = data;
  const isScheduled = metadata?.isScheduled;
  const isScheduledMessage = metadata?.message;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: metadata?.jobId,
  });
  const { checkElementVisibility } = usePermissionChecks();

  // Create action menu
  const actionMenu = () => {
    return {
      menuItems: [
        {
          name: "Move to another machine",
          action: () => setIsRescheduleDialogOpen(true),
          disabled: !checkElementVisibility(PermissionVisualElements.schedulingMoveToAnotherMachine),
        },
        {
          name: "Remove build file",
          action: () => handleOpenDialog(),
          disabled: !checkElementVisibility(PermissionVisualElements.schedulingRemoveBuildFile),
          type: "error",
        },
      ],
    };
  };

  // Popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [popoverContent, setPopoverContent] = useState(null);

  // Rechedule dialog
  const [isRescheduleDialogOpen, setIsRescheduleDialogOpen] = useState(false);

  // Confirmation dialog
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async (event, bfmId) => {
    event.stopPropagation();
    await putUnscheduleBuildFile(bfmId, metadata.jobId, metadata.machineId);
    handleCloseDialog();
  };

  const handleClick = async (event, id) => {
    setAnchorEl(event.currentTarget);
    if (id) {
      setIsLoading(true);
      let response = await onClickItem(id);
      setPopoverContent(response);
      setIsLoading(false);
    }
  };

  const handleUnlock = async (id) => {
    const payload = {
      machineId: id,
      machineName: itemTitle,
    };
    await unlock(payload);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const dragStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? "100" : "auto",
    opacity: isDragging ? 0.3 : 1,
  };

  let ctrlButtons;
  const disableActions = metadata?.initiationStatus === "PENDING" || metadata?.initiationStatus === "STARTED";

  if (typeName !== "Build file") {
    ctrlButtons = (
      <>
        {lock && (
          <Tooltip title="Unlock the machine" arrow>
            <span>
              <IconButton
                aria-label="unlock"
                onClick={() => handleUnlock(data.id)}
                id="id-unlock-machine"
                disabled={!checkElementVisibility(PermissionVisualElements.unlockScheduleMachine)}
              >
                <icons.mui.LockOpen fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        )}

        <IconButton aria-label="machine-info" onClick={onClickItem ? (e) => handleClick(e, data.id) : null}>
          <icons.mui.MoreVert fontSize="small" />
        </IconButton>
      </>
    );
  } else {
    ctrlButtons = (
      <>
        {!isScheduled && !disableActions && (
          <Box sx={{ display: "flex", padding: "0px 0.4rem" }}>
            <Tooltip title={isScheduledMessage} arrow>
              <CircularProgress color="success" size={15} thickness={6} disableShrink />
            </Tooltip>
          </Box>
        )}
        {metadata?.jobId && (
          <Tooltip
            title={
              <>
                <div>
                  <span style={{ fontWeight: "bold" }}>Job ID:</span> {metadata.jobId}
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>Lot ID:</span>{" "}
                  {metadata.lotId !== "undefined" ? metadata.lotId : "Not available"}
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>Build file ID:</span> {id}
                </div>
                {["PENDING", "FAILURE", "STARTED"].includes(metadata.initiationStatus) && (
                  <>
                    <Divider sx={{ margin: "0.3rem 0" }} />
                    <div>
                      <span style={{ fontWeight: "bold" }}>Build initiation:</span> {metadata.initiationNotification}
                    </div>
                  </>
                )}
              </>
            }
            arrow
          >
            <span>
              <IconButton data-syklone="scheduling-build-file-info" disabled={disableActions}>
                <icons.mui.Info
                  fontSize="small"
                  color={["FAILURE"].includes(metadata.initiationStatus) ? "error" : ""}
                />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {!disableActions && isDraggable ? (
          <Tooltip title={`Drag and drop to reschedule build file ${lock ? "is locked" : ""}`} arrow>
            <span>
              <IconButton
                {...attributes}
                {...listeners}
                aria-label="drag build file"
                data-syklone="scheduling-drag-item-button"
                id="id-scheduling-drag-item-button"
                disabled={!checkElementVisibility(PermissionVisualElements.schedulingDragItemButton) || lock}
              >
                <icons.mui.DragIndicator fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          ""
        )}

        <WidgetPopoverMenu id="id-scheduling" data={actionMenu()} disabled={lock || disableActions} />
      </>
    );
  }

  return (
    <>
      <DialogCommonConfirmation
        contentHeight={50}
        title="Confirm deletion"
        onConfirm={(event) => handleConfirmDelete(event, id)}
        onCancel={handleCloseDialog}
        onClose={handleCloseDialog}
        buttonOkDisabled={true}
        isOpen={openDialog}
      >
        Are you sure you want to delete this item?
      </DialogCommonConfirmation>

      <DialogMoveToMachine
        alert={alert}
        setAlert={setAlert}
        data={{
          bfId: id,
          machine: machineValidity,
          metadata: metadata,
          status: status,
        }}
        machineData={machineData}
        isOpen={isRescheduleDialogOpen}
        setIsOpen={setIsRescheduleDialogOpen}
        onSubmit={putRescheduleToAnotherMachine}
        isLoading={isRescheduleLoading}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid container direction="column" rowSpacing={1} sx={{ p: 1.5, width: 320 }}>
          {!isLoading ? (
            <>
              <Grid item>
                <Grid container direction="column" spacing={0.1}>
                  <Grid item xs={12} sx={{ opacity: 0.6 }}>
                    Name
                  </Grid>
                  <Grid item xs={12}>
                    {popoverContent?.name}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" spacing={0.1}>
                  <Grid item xs={12} sx={{ opacity: 0.6 }}>
                    Description
                  </Grid>
                  <Grid item xs={12}>
                    {popoverContent?.description}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" spacing={0.1}>
                  <Grid item xs={12} sx={{ opacity: 0.6 }}>
                    Domain name
                  </Grid>
                  <Grid item xs={12}>
                    {popoverContent?.domain_name}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" spacing={0.1}>
                  <Grid item xs={12} sx={{ opacity: 0.6 }}>
                    Location
                  </Grid>
                  <Grid item xs={12}>
                    {popoverContent?.location}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" spacing={0.1}>
                  <Grid item xs={12} sx={{ opacity: 0.6 }}>
                    Is qualified for production
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ textTransform: "capitalize" }}>{`${isQualifiedForProduction(
                      popoverContent?.is_qualified_for_production
                    )}`}</span>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            "Loading..."
          )}
        </Grid>
      </Popover>

      <Box style={isDraggable ? dragStyle : null} ref={setNodeRef}>
        <ScheduleItemContainer container direction="column" data-syklone="scheduling-row-item" sx={{ width: width }}>
          <Grid item>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <ScheduleItemType>
                  <ScheduleItemTypography>{typeName}</ScheduleItemTypography>
                </ScheduleItemType>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>{ctrlButtons}</Box>
            </Box>
          </Grid>

          <Grid item sx={{ display: "flex", gap: "0.5rem" }}>
            {typeName !== "Build file" && <WidgetScheduleCurrentBuild data={machineStatus} />}
            <Grid container direction="column">
              <ScheduleItemTitle item>
                <ScheduleItemTypography sx={{ width: 170 }}>
                  <WidgetOverflowTip>{itemTitle}</WidgetOverflowTip>
                </ScheduleItemTypography>
              </ScheduleItemTitle>

              <ScheduleItemSubtitle item>
                <ScheduleItemTypography sx={{ maxWidth: 230 }}>
                  <WidgetOverflowTip>
                    {typeName === "Build file" ? `${itemSubTitlePrefix}: ${itemSubTitle}` : itemSubTitle}
                  </WidgetOverflowTip>
                </ScheduleItemTypography>
              </ScheduleItemSubtitle>

              <Grid item>
                <StatusBox fontColor={status.type} type={typeName}>
                  <ScheduleItemTypography>{status.name}</ScheduleItemTypography>
                </StatusBox>
              </Grid>
            </Grid>
          </Grid>
        </ScheduleItemContainer>
      </Box>
    </>
  );
}

WidgetScheduleRowItem.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.string,
  isDraggable: PropTypes.bool,
  width: PropTypes.number,
  onClickItem: PropTypes.func,
  putUnscheduleBuildFile: PropTypes.func,
  machineValidity: PropTypes.array,
  putRescheduleToAnotherMachine: PropTypes.func,
  isRescheduleLoading: PropTypes.bool,
  unlock: PropTypes.func,
  machineStatus: PropTypes.object,
  machineData: PropTypes.array,
};

export default WidgetScheduleRowItem;
