import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Box,
  Button,
  Popover,
  Checkbox,
  InputLabel,
  OutlinedInput,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  styled,
  Typography,
} from "syklone/ui/index.js";
import { useQueryClient } from "syklone/libraries/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { BUILD_FILES_PROMOTION_TAB_VALUES } from "../../core/index.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomPopover = styled(Popover)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& > .MuiPaper-root": {
    backgroundColor: "#252525",
    backgroundImage: "none",
  },
});

function WidgetBfPromotionDialog({
  getAnchorElement,
  onClosePromoteDialog,
  productName,
  buildFileId,
  promotionGroupData,
  promotionApprovalTypesData,
  tabValue,
}) {
  const apiInstance = useApiContext();
  const queryClient = useQueryClient();
  const { showSnackbar } = useAlertSnackbar();

  const serviceAuth = apiInstance.sykloneApi.serviceAuth;

  // Popover props
  const open = Boolean(getAnchorElement());
  const id = open ? "promotion-dialog" : undefined;

  // imports: local
  const [userListData, setUserListData] = React.useState([]);

  // Select box groups
  const [userGroupUser, setUserGroupUser] = React.useState("");
  const [userGroupUserId, setUserGroupUserId] = React.useState("");

  //Multiselect box list of users
  const [userEmail, setUserEmail] = React.useState([]);
  const [userEmailList, setUserEmailList] = React.useState([]);
  const [userIdEmail, setUserIdEmail] = React.useState([]);
  const [isPromoting, setIsPromoting] = React.useState(false);

  const onChangeUserGroup = async (event) => {
    setUserGroupUser(event.target.value);
    const selectedGroupValue = event.target.value.toLowerCase();
    const selectedGroup = promotionGroupData.find((item) => item.name.toLowerCase().includes(selectedGroupValue));
    if (selectedGroup) {
      const members = await serviceAuth.getAdminGroupsMembersByGroupId(selectedGroup.id);
      const membersList = members.data.data;
      setUserListData(membersList);
      setUserEmail([]);
      setUserEmailList(membersList.map((item) => item.email));
      setUserGroupUserId(selectedGroup.id);
      setUserIdEmail([]);
    }
  };

  const onChangeMultiSelect = (event) => {
    const {
      target: { value },
    } = event;
    setUserIdEmail(value.map((item) => userListData?.find((o) => o.email === item)));
    setUserEmail(typeof value === "string" ? value.split(",") : value);
  };

  const onCloseResetState = () => {
    onClosePromoteDialog();
    setUserGroupUser("");
    setUserEmail([]);
    setUserEmailList([]);
    setUserIdEmail([]);
    setUserListData([]);
  };

  const getApprovalValueByLabel = (label) => {
    const approvalTypeItem = promotionApprovalTypesData.find((item) => item.label === label);
    return approvalTypeItem ? approvalTypeItem.value : null;
  };

  const getApprovalType = (collection) => {
    let approvalType;
    switch (collection) {
      case BUILD_FILES_PROMOTION_TAB_VALUES[0]:
        approvalType = getApprovalValueByLabel("PROMOTE_BUILD_FILE_TO_UNDER_REVIEW");
        break;
      case BUILD_FILES_PROMOTION_TAB_VALUES[1]:
        approvalType = getApprovalValueByLabel("PROMOTE_BUILD_FILE_TO_RELEASED");
        break;
      case BUILD_FILES_PROMOTION_TAB_VALUES[2]:
        break;
      default:
        break;
    }
    return approvalType;
  };

  const promoteBuildFile = async (buildFileId, users, group, collection) => {
    const data = {
      ["approval_type"]: getApprovalType(collection),
      ["collection"]: collection,
      ["approval_group"]: group,
      approvers: users,
    };
    setIsPromoting(true);
    try {
      let response = await serviceAuth.postBuildFilePromoteByBuildFileId(buildFileId, data);
      if (response.data.status === 200) {
        const message = response?.data?.message ? response?.data?.message : "Build file promoted successfully";
        showSnackbar(message, "info");
        onCloseResetState();
        queryClient.invalidateQueries("check-notification");
      }
    } catch (error) {
      const errorResponseMessage = error?.data?.detail;
      const errorMessage = errorResponseMessage ? errorResponseMessage : "There was an error promoting the build file";
      showSnackbar(errorMessage, "error");
    } finally {
      setIsPromoting(false);
    }
  };

  const onClickPromote = () => {
    let users = userIdEmail.map((item) => item.id);
    let group = userGroupUserId;
    promoteBuildFile(buildFileId, users, group, tabValue, null);
  };

  return (
    <CustomPopover
      id={id}
      open={open}
      onClose={onCloseResetState}
      anchorReference={"none"}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box sx={styles.promotionWrapper} data-syklone="buildfiles-promotion-wrapper">
        <Box>
          <Box>
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>Promote slice part</Typography>
          </Box>
          <Box>
            <Typography sx={styles.superTitle}>{productName}</Typography>
          </Box>
        </Box>

        <FormControl sx={{ mb: 1, mt: 2, width: "100%" }} data-syklone="buildfiles-promotion-group">
          <InputLabel id="promotion-group-label">Group</InputLabel>
          <Select
            labelId="promotion-group-label"
            id="promotion-group"
            value={userGroupUser}
            label="Group"
            onChange={onChangeUserGroup}
          >
            {promotionGroupData.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {userGroupUser !== "" && (
          <FormControl sx={{ mb: 2, width: "100%" }} data-syklone="buildfiles-promotion-users">
            <InputLabel id="promotion-multiple-checkbox-label">Users</InputLabel>
            <Select
              labelId="promotion-multiple-checkbox-label"
              id="promotion-multiple-checkbox"
              multiple
              value={userEmail}
              onChange={onChangeMultiSelect}
              input={<OutlinedInput label="Group" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {userEmailList?.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={userEmail.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
              <Button
                disabled={(userEmail && userEmail.length === 0) || isPromoting}
                variant="contained"
                onClick={() => onClickPromote()}
                sx={{
                  color: "#000",
                  "&:hover": {
                    color: "#fff",
                  },
                }}
                data-syklone="buildfiles-promotion-button"
              >
                Promote
              </Button>
            </Box>
          </FormControl>
        )}
      </Box>
    </CustomPopover>
  );
}

WidgetBfPromotionDialog.propTypes = {
  getAnchorElement: PropTypes.any,
  onClosePromoteDialog: PropTypes.func,
  productName: PropTypes.string,
  buildFileId: PropTypes.string,
  promotionGroupData: PropTypes.array,
  tabValue: PropTypes.string,
  snackBar: PropTypes.object,
  promotionApprovalTypesData: PropTypes.array,
};

const styles = {
  promotionWrapper: {
    padding: "15px 18px",
    display: "flex",
    flexDirection: "column",
    width: "400px",
  },
  superTitle: {
    opacity: ".5",
    fontSize: 12,
  },
};

export default WidgetBfPromotionDialog;
