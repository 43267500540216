import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

// imports: syklone
import { AuthContext, useApiContext, useAuthRedirect, usePermissionChecks } from "syklone/api/react/index.js";
import { DialogProfile } from "syklone/components/dialogs/index.js";
import {
  WidgetTopBarContainer,
  WidgetTopBarRightControlContainer,
  WidgetAvatar,
} from "syklone/components/widgets/index.js";
import { WidgetAvatarNotifications } from "../../widgets/index.js";
import { Button } from "syklone/ui/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";
import { useAuthSelector } from "syklone/api/react/auth/index.js";

function UseQuery(props) {
  return props.children(useQuery(props.keyName, props.fn, props.options));
}

const getUsername = (auth) => {
  return auth.user ? auth.user.name : undefined;
};

const formatEmailToName = (email) => {
  if (!email || !email.includes("@")) {
    return "";
  }
  const namePart = email.split("@")[0];
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  return namePart.includes(".")
    ? namePart.split(".").map(capitalizeFirstLetter).join(" ")
    : capitalizeFirstLetter(namePart);
};

function DashboardWithoutSidebar(props) {
  const { checkElementVisibility } = usePermissionChecks();
  const queryClient = useQueryClient();
  const permissions = useAuthSelector((state) => state.permissions);
  const api = useApiContext();
  const [promotionNotificationsData, setPromotionNotificationsData] = useState([]);
  const refAvatarNotifications = useRef(null);
  const refDialogProfile = useRef(null);
  const authRedirect = useAuthRedirect();
  const navigate = useNavigate();
  const [_onAbout] = useOutletContext();

  const createProfileDialog = () => {
    return (
      <AuthContext.Consumer>
        {(auth) => (
          <DialogProfile
            ref={refDialogProfile}
            onOpen={() => {
              refAvatarNotifications.current.handleClose();
            }}
            data={auth?.user}
          />
        )}
      </AuthContext.Consumer>
    );
  };

  const checkNotification = async () => {
    if (checkElementVisibility(PermissionVisualElements.hasNotificationsPermission)) {
      try {
        let response = await api.sykloneApi.serviceAuditTrail.getPendingApprovals();
        const notifications = response.data.data.map((item) => ({
          contentTypeId: item["content_type_id"],
          id: item.id,
          objectId: item["object_id"],
          requestor: item.requestor,
          type: item.type,
          name: formatEmailToName(item.requestor),
          requestDate: item["request_date"],
        }));
        setPromotionNotificationsData(notifications);
        return response;
      } catch (error) {
        console.error("Error fetching notifications:", error);
        return [];
      }
    } else {
      setPromotionNotificationsData([]);
      return [];
    }
  };

  const onLogout = async () => {
    await authRedirect.initiateAuthLogoutRedirect();
  };

  useEffect(() => {
    queryClient.invalidateQueries(["check-notification"]);
    checkNotification();
  }, [api, permissions]);

  const createTopBarContainer = () => {
    const handleCloseWidgetAvatar = () => {
      refAvatarNotifications.current.handleClose();
    };

    return (
      <WidgetTopBarContainer
        style={{
          padding: "0px 20px",
          paddingResponsive: "0px 20px",
          justifyContent: "end",
        }}
        hasBorder={true}
      >
        <WidgetTopBarRightControlContainer>
          <Button onClick={() => navigate("/dashboard")} data-syklone="back-to-dashboard">
            Back to Dashboard
          </Button>
          <UseQuery
            keyName={["check-notification"]}
            fn={() => checkNotification()}
            options={{
              staleTime: 0,
              refetchInterval: 5000,
            }}
          >
            {(query) => {
              return (
                <AuthContext.Consumer>
                  {(auth) => (
                    <WidgetAvatar
                      ref={refAvatarNotifications}
                      username={getUsername(auth)}
                      hasNotification={promotionNotificationsData.length > 0}
                      promotionNotifcationData={promotionNotificationsData}
                      onAbout={_onAbout}
                      onProfile={() => refDialogProfile.current.setDialogOpen(true)}
                      onLogout={() => onLogout()}
                    >
                      <WidgetAvatarNotifications
                        key="widget-avatar-notifications"
                        promotionNotifcationData={promotionNotificationsData}
                        closeMenu={handleCloseWidgetAvatar}
                        refreshFunc={checkNotification}
                      />
                    </WidgetAvatar>
                  )}
                </AuthContext.Consumer>
              );
            }}
          </UseQuery>
        </WidgetTopBarRightControlContainer>
      </WidgetTopBarContainer>
    );
  };

  return (
    <>
      {createProfileDialog()}
      {createTopBarContainer()}
      <Outlet />
    </>
  );
}

export default DashboardWithoutSidebar;
