import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// imports: syklone
import {
  AzureAuthButton,
  DemoAuthButton,
  useAuthContext,
  useApiContext,
  useAuthFlow,
} from "syklone/api/react/index.js";

const DevAuthButtons = () => {
  const navigate = useNavigate();
  return <DemoAuthButton onClick={() => navigate("dashboard/part-platform")} />;
};

const AuthButtons = () => {
  const { initiateAuthLogin } = useAuthFlow();
  return (
    <AzureAuthButton
      onClick={() => initiateAuthLogin("syklone")}
      text="Login with ENTRA ID"
      dataTag="entra-id-login-button"
    />
  );
};

const WidgetAuthButtons = () => {
  const apiInstance = useApiContext();
  const { isProviderAvailable } = useAuthContext();
  return !isProviderAvailable || (!isProviderAvailable && apiInstance.isDebug) ? <DevAuthButtons /> : <AuthButtons />;
};

WidgetAuthButtons.propTypes = {
  loginFormAlignment: PropTypes.string,
};

export default WidgetAuthButtons;
