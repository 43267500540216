import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { fileDownload, useQuery, useMutation, useQueryClient } from "syklone/libraries/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { dataPageProductDetails, dataSearchResults, dataBuildHistory } from "../../data/index.js";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const withPageProductDetails = (Component) => {
  const PageProductDetails = () => {
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();
    const location = useLocation();
    const sykloneApi = apiInstance.sykloneApi;
    const image = location.state.image;
    const productId = location.state.id;

    const [data, setData] = useState({
      dataPageProductDetails: dataPageProductDetails,
      dataBuildHistory: dataBuildHistory,
      image: image,
    });

    // Loading state
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isBuildFileLoading, setIsBuildFileLoading] = useState(false);

    // Dialog state
    const [filesList, setFilesList] = useState(null);
    const [isDialogAddFilesOpen, setIsDialogAddFilesOpen] = useState(false);

    // Alert state
    const { showSnackbar } = useAlertSnackbar();
    const [alert, setAlert] = useState({ message: "", severity: "", snackBar: false, component: "" });

    // Files menu
    /* eslint-disable */
    const dataMenu = {
      menuItems: [
        {
          name: "Download",
          action: (_event, state) => onClickDownloadProductFiles.mutate({ id: productId, name: state.name }),
        },
        {
          name: "Delete",
          action: (_event, state) => onClickDeleteProductFamilyFiles.mutate({ id: productId, blob: state.name }),
          type: "error",
        },
      ],
    };

    const getSearchResults = async () => {
      setIsSearchLoading(true);
      await timeout(500);
      setIsSearchLoading(false);
      return dataSearchResults;
    };

    const updateFilesState = (array) => {
      let files = array.map((item) => {
        return {
          link: item.blob_path,
          name: item.name,
        };
      });

      setData((prevState) => ({
        ...prevState,
        dataPageProductDetails: {
          ...prevState.dataPageProductDetails,
          assets: {
            ...prevState.dataPageProductDetails.assets,
            files: files,
          },
        },
      }));
    };

    const { isSuccess: isSuccessAssets, data: productAssets } = useQuery({
      queryKey: ["productAssets"],
      queryFn: () => sykloneApi.bcGetProductAssetsById(productId),
      enabled: true,
      onError: () => showSnackbar("Error getting product assets.", "error"),
    });

    const { isSuccess: isSuccessProduct, data: productData } = useQuery({
      queryKey: ["productData"],
      queryFn: () => sykloneApi.bcGetProductsById(productId),
      enabled: true,
      onError: () => showSnackbar("Error getting product.", "error"),
    });

    const getBuildFileData = async (id) => {
      try {
        setIsBuildFileLoading(true);
        const response = await sykloneApi.bcGetReleasedBuildFileById(id);
        setIsBuildFileLoading(false);
        return response.name;
      } catch (error) {
        showSnackbar("Error getting build file.", "error");
      }
    };

    const getAuditTrailHistory = async (name) => {
      const queryParams = {
        electronicRecordType: "BuildFile",
        name: name,
      };

      try {
        return await sykloneApi.bcGetAuditTrailHistory(queryParams);
      } catch (error) {
        const message = error?.response?.data?.message ? error?.response?.data?.message : "Error getting data!";
        showSnackbar(message, "error");
        return error;
      }
    };

    useEffect(() => {
      if (isSuccessAssets) {
        updateFilesState(productAssets.data.data);
      }
    }, [productAssets]);

    useEffect(() => {
      if (isSuccessProduct) {
        const fetchData = async () => {
          const buildFilesData = await Promise.all(
            productData.data.data.build_file_id.map(async (item, index) => {
              const buildFileName = await getBuildFileData(item);
              return {
                id: index,
                buildFileId: item,
                name: buildFileName,
                status: "Released",
              };
            })
          );

          setData((prevState) => ({
            ...prevState,
            dataPageProductDetails: {
              ...prevState.dataPageProductDetails,
              buildFiles: buildFilesData,
              product: {
                family: productData.data.data.family,
                model: productData.data.data.model,
                productId: productData.data.data.product_id,
                size: productData.data.data.size,
              },
            },
          }));
        };

        fetchData();
      }
    }, [productData]);

    // Add product files
    const onClickAddProductFiles = useMutation(
      async (id) => {
        const formData = new FormData();
        for (let element of filesList) {
          formData.append("files", element);
        }
        let response = await sykloneApi.bcPostProductFiles(id, formData);
        return response.data;
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["productAssets"] });
          updateFilesState(data.data.assets);
          showSnackbar("File/files uploaded successfully.", "success");
          setIsDialogAddFilesOpen(false);
          setFilesList(null);
        },
        onError: (error) => {
          setFilesList(null);
          // TODO: Alert Message
          setAlert({
            message: error.response.data.detail,
            severity: "error",
            snackBar: false,
            component: "DialogAddFiles",
          });
        },
      }
    );

    // Download product file
    const onClickDownloadProductFiles = useMutation(
      async ({ id, name }) => {
        const queryParams = {
          blob_name: name,
        };
        let response = await sykloneApi.bcGetProductFilesById(id, queryParams, {
          responseType: "blob",
        });
        return {
          data: response.data,
          blob: name,
        };
      },
      {
        onSuccess: ({ data, blob }) => {
          fileDownload(data, blob);
        },
        onError: (error) => {
          showSnackbar("File download error.", "error");
        },
      }
    );

    // Delete product file
    const onClickDeleteProductFamilyFiles = useMutation(
      async ({ id, blob }) => {
        const queryParams = {
          blob_name: blob,
        };
        let response = await sykloneApi.bcDeleteProductAssetsById(id, queryParams);
        return {
          data: response.data,
          blob: blob,
        };
      },
      {
        onSuccess: ({ data, blob }) => {
          queryClient.invalidateQueries({ queryKey: ["productAssets"] });
          updateFilesState(data.data.assets);
          showSnackbar(`File "${blob}" deleted successfully.`, "success");
          setFilesList(null);
        },
        onError: (error) => {
          setFilesList(null);
          const message = error?.response?.data?.message
            ? error.response.data.message
            : "There was an error deleting the asset";
          showSnackbar(message, "error");
        },
      }
    );

    const isDownloading = onClickDownloadProductFiles.isLoading;
    const isDeleting = onClickDeleteProductFamilyFiles.isLoading;
    const isAddingFiles = onClickAddProductFiles.isLoading;

    return (
      <Component
        id={productId}
        alert={alert}
        setAlert={setAlert}
        data={data}
        dataMenu={dataMenu}
        dialogState={{ filesList, isDialogAddFilesOpen }}
        dialogFn={{ setFilesList, setIsDialogAddFilesOpen }}
        getSearchResults={getSearchResults}
        getAuditTrailHistory={getAuditTrailHistory}
        isLoading={{ isSearchLoading, isDownloading, isDeleting, isBuildFileLoading, isAddingFiles }}
        onClickAddProductFile={onClickAddProductFiles.mutate}
        onClickDownloadProductFiles={onClickDownloadProductFiles.mutate}
      />
    );
  };
  PageProductDetails.displayName = "PageProductDetails";
  PageProductDetails.propTypes = {};
  return PageProductDetails;
};

export default withPageProductDetails;
