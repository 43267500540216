import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { moment } from "syklone/libraries/index.js";
import { Grid, icons, IconButton, styled, Tooltip, Typography } from "syklone/ui/index.js";
import { usePermissionChecks } from "syklone/api/react/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

// imports: local
import { MachineActiveImage, BuildingSuccess, BuildingWarning, BuildingError } from "./assets/img/index.js";

const WidgetScheduleRowPlayStatus = styled(Grid)({
  opacity: 0.6,
});

const WidgetScheduleRowTypography = styled(Typography)({
  fontSize: 11,
});

const createDate = () => {
  let date = new Date();
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

function insertSpaces(str) {
  return str?.replace(/([a-z])([A-Z])/g, '$1 $2');
}

/* eslint-disable camelcase */
function WidgetScheduleRowPlay({ active, machine, machineStatus, buildFileData, dialogFn, isDisabled }) {
  const { setBuildStartDialogOpen, setBuildStartDialogData } = dialogFn;
  const { checkElementVisibility } = usePermissionChecks();
  const { bfm_id, print_status } = machineStatus;

  const onClickStart = () => {
    setBuildStartDialogOpen(true);
    setBuildStartDialogData({ machine: machine, buildFileData: buildFileData });
  };

  if (!active) {
    return (
      <Grid container alignItems="center" direction="column" rowSpacing={0}>
        {buildFileData.length > 0 && (
          <>
            <WidgetScheduleRowPlayStatus item>
              <WidgetScheduleRowTypography>Machine status</WidgetScheduleRowTypography>
            </WidgetScheduleRowPlayStatus>
            <Grid item>
              <WidgetScheduleRowTypography
                sx={{
                  color: print_status === "ConnectionError" ? "#A80909" : "#ffffff",
                }}
              >
                {insertSpaces(print_status)}
              </WidgetScheduleRowTypography>
            </Grid>
          </>
        )}

        {["Idle", "JobLoaded", "ConnectionError"].includes(print_status) || buildFileData.length === 0 ? (
          <Grid item>
            <Tooltip title="Start machine" placement="bottom" arrow>
              <span>
                <IconButton
                  aria-label="start machine"
                  onClick={() => onClickStart()}
                  disabled={
                    isDisabled || !checkElementVisibility(PermissionVisualElements.schedulingSchedulePlayButton)
                  }
                  color="primary"
                  data-syklone="schedule-play-button"
                  id="id-schedule-play-button"
                >
                  <icons.mui.PlayCircle fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        ) : (
          <Grid item>
            {["Building", "BuildSucceeded"].includes(print_status) && <img src={BuildingSuccess} alt="" />}
            {["BuildPaused", "UserInterventionRequired", "Busy"].includes(print_status) && (
              <img src={BuildingWarning} alt="" />
            )}
            {["BuildFailed", "BuildCanceled"].includes(print_status) && <img src={BuildingError} alt="" />}
          </Grid>
        )}

        {buildFileData.length > 0 && (
          <>
            <WidgetScheduleRowPlayStatus item>
              <WidgetScheduleRowTypography>Status updated</WidgetScheduleRowTypography>
            </WidgetScheduleRowPlayStatus>
            <Grid item>
              <WidgetScheduleRowTypography>{bfm_id ? createDate(machineStatus.time) : "N/A"}</WidgetScheduleRowTypography>
            </Grid>
          </>
        )}
      </Grid>
    );
  } else {
    return (
      <Grid container alignItems="center" direction="column" rowSpacing={0}>
        <WidgetScheduleRowPlayStatus item>
          <WidgetScheduleRowTypography>Status</WidgetScheduleRowTypography>
        </WidgetScheduleRowPlayStatus>
        <Grid item>
          <WidgetScheduleRowTypography>Active</WidgetScheduleRowTypography>
        </Grid>
        <Grid item>
          <img src={MachineActiveImage} alt="" />
        </Grid>
        <WidgetScheduleRowPlayStatus item>
          <WidgetScheduleRowTypography>Date started:</WidgetScheduleRowTypography>
        </WidgetScheduleRowPlayStatus>
        <Grid item>
          <WidgetScheduleRowTypography>{createDate()}</WidgetScheduleRowTypography>
        </Grid>
      </Grid>
    );
  }
}

WidgetScheduleRowPlay.propTypes = {
  active: PropTypes.bool,
  machine: PropTypes.object,
  machineStatus: PropTypes.object,
  buildFileData: PropTypes.any,
  dialogFn: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default WidgetScheduleRowPlay;
