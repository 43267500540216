import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// imports: syklone
import { Box } from "syklone/ui/index.js";
import { useAuthContext } from "syklone/api/react/index.js";
import { FormLogin } from "syklone/components/forms/index.js";

// imports: local
import { LayoutGfLogin } from "../../layouts/index.js";

function PageGfLogin({ successRoute }) {
  const { login } = useAuthContext();
  const [loggedIn, setIsLoggedIn] = React.useState(false);
  const navigate = useNavigate();

  const onLogin = async (email, password, cb) => {
    const isProviderLoaded = true;
    if (isProviderLoaded) {
      try {
        await login({ email, password, cb });
        if (successRoute) {
          navigate(successRoute);
        } else {
          setIsLoggedIn(true);
        }
      } catch (e) {
        const error = { message: e?.detail || "There was an error logging in." };
        cb(error);
      }
    } else {
      cb({ message: "Provider is not Loaded" });
    }
  };
  return (
    <LayoutGfLogin>
      {loggedIn ? (
        <Box>You have successfully logged in.</Box>
      ) : (
        <FormLogin onLogin={onLogin} isThemeLight={false} hideRememberMe hideFrogotPassword hidePassword />
      )}
    </LayoutGfLogin>
  );
}

PageGfLogin.propTypes = {
  successRoute: PropTypes.string,
};

export default PageGfLogin;
