import React, { Component } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

// imports: syklone
import { icons, Box, Button, Grid, Typography, Tabs, Tab, Resizable, styled } from "syklone/ui/index.js";
import { DialogProfile } from "syklone/components/dialogs/index.js";
import {
  WidgetQuickActions,
  WidgetAvatar,
  WidgetTopBarContainer,
  WidgetTopBarRightControlContainer,
} from "syklone/components/widgets/index.js";
import { Drawer } from "syklone/ui/core/index.js";
import { AuthContext, withApiContext, withAuthRedirect, usePermissionChecks } from "syklone/api/react/index.js";
import { withDataTag } from "syklone/components/hoc/index.js";
import { useQuery } from "syklone/libraries/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";
import { withPermissionsCheck, withAuthContext } from "syklone/api/react/auth/auth_context/index.js";

// imports: local
import * as img from "../../resources/index.js";
import { WidgetAvatarNotifications, WidgetMachineCards } from "../../widgets/index.js";
import { withOutletContext } from "../../hoc/index.js";

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  "& > .MuiPaper-root": {
    backgroundImage: "none",
    backgroundColor: theme.palette.mode === "dark" ? "#252525" : "#f8f8f8",
  },
}));

const formatEmailToName = (email) => {
  // Check if the email is valid
  if (!email || !email.includes("@")) {
    return "";
  }

  // Extract the name part from the email
  const namePart = email.split("@")[0];

  // Check for a dot in the name part
  if (namePart.includes(".")) {
    return namePart.split(".").map(capitalizeFirstLetter).join(" ");
  } else {
    return capitalizeFirstLetter(namePart);
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

function UseQuery(props) {
  return props.children(useQuery(props.keyName, props.fn, props.options));
}

function convertString(input) {
  return input.toLowerCase().replace(/\s+/g, "-");
}

function _CreateQuickActionList({ api, navigate }) {
  const { checkElementVisibility } = usePermissionChecks();
  const quickActionPartCreatorVisibility = checkElementVisibility(
    PermissionVisualElements.dashboardQuickActionPartCreator
  );
  const quickActionPlatformCreatorVisibility = checkElementVisibility(
    PermissionVisualElements.dashboardQuickActionPlatformCreator
  );
  const quickActionParametersVisibility = checkElementVisibility(
    PermissionVisualElements.dashboardQuickActionParameters
  );
  const quickActionProductsVisibility = checkElementVisibility(PermissionVisualElements.dashboardQuickActionProducts);
  const quickActionSchedulingVisibility = checkElementVisibility(
    PermissionVisualElements.dashboardQuickActionScheduling
  );
  const quickActionMachinesVisibility = checkElementVisibility(PermissionVisualElements.dashboardQuickActionMachines);

  const items = [
    {
      name: "Part",
      type: "Action",
      props: {
        image: img.IconGfPartCreator,
        onClick: () => {
          if (!quickActionPartCreatorVisibility) {
            console.log("No permissions.");
          } else {
            window.open(api.webAppUrls.urlPagePartCreator, "_blank");
          }
        },
      },
      isDisabled: !quickActionPartCreatorVisibility,
    },
    {
      name: "Platform",
      type: "Action",
      props: {
        image: img.IconGfPlatformCreator,
        onClick: () => {
          if (!quickActionPlatformCreatorVisibility) {
            console.log("No permissions.");
          } else {
            window.open(api.webAppUrls.urlPagePlatformCreator, "_blank");
          }
        },
      },
      isDisabled: !quickActionPlatformCreatorVisibility,
    },
    {
      name: "Parameters",
      type: "Action",
      props: {
        image: img.IconGfBuildCreator,
        onClick: () => {
          if (!quickActionParametersVisibility) {
            console.log("No permissions.");
          } else {
            window.open(api.webAppUrls.urlPageBuildCreator, "_blank");
          }
        },
      },
      isDisabled: !quickActionParametersVisibility,
    },
    {
      name: "Products",
      type: "Action",
      props: {
        image: img.IconGfProducts,
        onClick: () => {
          if (!quickActionProductsVisibility) {
            console.log("No permissions.");
          } else {
            navigate("/dashboard/product-families");
          }
        },
      },
      isDisabled: !quickActionProductsVisibility,
    },
    {
      name: "Scheduling",
      type: "Action",
      props: {
        image: img.IconGfSchedule,
        onClick: () => {
          if (!quickActionSchedulingVisibility) {
            console.log("No permission.");
          } else {
            navigate("/dashboard/scheduling");
          }
        },
      },
      isDisabled: !quickActionSchedulingVisibility,
    },
    {
      name: "Machines",
      type: "Action",
      props: {
        image: img.IconGfMachines,
        onClick: () => {
          if (!quickActionMachinesVisibility) {
            console.log("No permissions.");
          } else {
            navigate("/dashboard/machines");
          }
        },
      },
      isDisabled: !quickActionMachinesVisibility,
    },
    {
      name: "Audit trail",
      type: "Action",
      props: {
        image: img.IconGfAuditTrail,
        onClick: () => {
          navigate("/audit");
        },
      },
    },
  ];
  return items.map((item, index) => {
    return (
      <Grid
        item
        key={index}
        data-syklone={`quick-action-${item.name}`}
        id={`id-quick-action-${convertString(item.name)}`}
      >
        <WidgetQuickActions data={item} isThemeLight={api.sessionSettings.data().isThemeLight} />
      </Grid>
    );
  });
}

_CreateQuickActionList.propTypes = {
  api: PropTypes.object,
  navigate: PropTypes.func,
};

const QuickActionsWrapper = withDataTag(Grid, "quick-actions");

class _DashboardWithSidebar extends Component {
  static propTypes = {
    history: PropTypes.object,
    navigate: PropTypes.func,
    location: PropTypes.object,
    context: PropTypes.func,
    match: PropTypes.func,
    api: PropTypes.object,
    authRedirect: PropTypes.object,
    contextValue: PropTypes.array,
    checkElementVisibility: PropTypes.func,
    auth: PropTypes.object,
    queryClient: PropTypes.object,
  };

  static APP_SIZING = {
    CONTAINER_GF_MIN_WIDTH: 1280,
  };

  static tabRoutes = ["/dashboard/part-platform", "/dashboard/scheduling", "/dashboard/machines"];

  _mounted = false;

  // -------- Public: Reactjs --------
  constructor(props) {
    super(props);
    this._setMounted(false);
    this.state = {
      windowInnerWidth: window.innerWidth,
      windowInnerHeight: window.innerHeight,
      leftColTabValue: "history",
      rightColMainTabValue: "/dashboard/part-platform",
      promotionNotifcationsData: [],
      sideBarOpen: false,
      isProfileOpen: false,
    };
    this.api = props.api;
    this.onHome = this.onHome.bind(this);
    this.refAvatarNotifications = React.createRef();
    this.refDialogProfile = React.createRef();
  }

  componentDidMount = () => {
    this._setMounted(true);
    this._updateMainTabIfRouteMatch();
    this._setWindowInnerSize();
    // every time the window is resized, the timer is cleared and set again
    // the net effect is the component will only reset after the window size
    // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
    // redrawing of the component for more complex components such as charts
    let movementTimer = null;
    const RESET_TIMEOUT = 100;
    if (this._isMounted()) {
      window.addEventListener("resize", () => {
        clearInterval(movementTimer);
        movementTimer = setTimeout(this._setWindowInnerSize, RESET_TIMEOUT);
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this._updateMainTabIfRouteMatch();
    }
    if (this.props.auth.permissions !== prevProps.auth.permissions) {
      this.props.queryClient.invalidateQueries(["check-notification"]);
      this._checkNotification();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this._setWindowInnerSize);
    this._setMounted(false);
  };

  // -------- Private: Getters/Setters --------
  _getUsername = (auth) => {
    return auth.user ? auth.user.name : undefined;
  };

  _setMounted = (value) => {
    this._mounted = value;
  };

  _isMounted = () => {
    return this._mounted;
  };

  _setWindowInnerSize = () => {
    if (this._isMounted()) {
      this.setState({
        windowInnerWidth: window.innerWidth,
        windowInnerHeight: window.innerHeight,
      });
    }
  };

  // -------- Private: Slots --------
  _onShowSidebar = (bool) => {
    this.setState({
      sideBarOpen: bool,
    });
  };

  _checkNotification = async () => {
    if (this.props.checkElementVisibility(PermissionVisualElements.hasNotificationsPermission)) {
      try {
        let response = await this.api.sykloneApi.serviceAuditTrail.getPendingApprovals();
        const promotionNotificationsData = response.data.data.map((item) => {
          return {
            contentTypeId: item["content_type_id"],
            id: item.id,
            objectId: item["object_id"],
            requestor: item.requestor,
            type: item.type,
            name: formatEmailToName(item.requestor),
            requestDate: item["request_date"],
          };
        });
        this.setState({
          promotionNotifcationsData: promotionNotificationsData,
        });
        return response;
      } catch (error) {
        console.log(error);
        // alert(`${error}`);
        return [];
      }
    } else {
      this.setState({
        promotionNotifcationsData: [],
      });
      return [];
    }
  };

  // -------- Public: Slots --------
  onHome = () => {
    this.props.navigate("/");
  };

  onLogout = async (authRedirect) => {
    console.log("authRedirect", this.props.authRedirect);
    await this.props.authRedirect.initiateAuthLogoutRedirect();
  };

  onProfile = () => {
    if (this.refDialogProfile.current) {
      this.refDialogProfile.current.setDialogOpen(true);
    }
  };

  // -------- Private: State management --------
  _updateMainTabIfRouteMatch = () => {
    _DashboardWithSidebar.tabRoutes.map((item) => {
      if (this.props.match(`${item}/*`, this.props.location.pathname)) {
        this.setState({
          rightColMainTabValue: item,
        });
      }
    });
  };

  // -------- Private: Component creation --------
  _createHistoryList = () => {
    const machineData = [
      {
        machineName: "LRM1K1",
        location: "Anngrove",
        status: {
          name: "Printing",
          type: 1,
        },
        metadata: {
          partNum: 4,
          dateStarted: 1716992631000,
          buildTime: "5h",
        },
      },
      {
        machineName: "LRM1K2",
        location: "Anngrove",
        status: {
          name: "Printing",
          type: 1,
        },
        metadata: {
          partNum: 4,
          dateStarted: 1716992631000,
          buildTime: "5h",
        },
      },
      {
        machineName: "LRM1K3",
        location: "Anngrove",
        status: {
          name: "Available",
          type: 2,
        },
      },
      {
        machineName: "LRM1K4",
        location: "Anngrove",
        status: {
          name: "Idle",
          type: 3,
        },
      },
      {
        machineName: "LRM1K5",
        location: "Anngrove",
        status: {
          name: "Idle",
          type: 3,
        },
      },
      {
        machineName: "LRM1K6",
        location: "Anngrove",
        status: {
          name: "Available",
          type: 2,
        },
      },
      {
        machineName: "LRM1K7",
        location: "Anngrove",
        status: {
          name: "Idle",
          type: 3,
        },
      },
      {
        machineName: "LRM1K8",
        location: "Anngrove",
        status: {
          name: "Available",
          type: 2,
        },
      },
      {
        machineName: "LRM1K9",
        location: "Anngrove",
        status: {
          name: "Available",
          type: 2,
        },
      },
      {
        machineName: "LRM1K10",
        location: "Anngrove",
        status: {
          name: "Available",
          type: 2,
        },
      },
      {
        machineName: "LRM1K11",
        location: "Anngrove",
        status: {
          name: "Available",
          type: 2,
        },
      },
      {
        machineName: "LRM1K12",
        location: "Anngrove",
        status: {
          name: "Available",
          type: 2,
        },
      },
    ];
    return <WidgetMachineCards data={machineData} onClick={() => console.log("Machine clicked")} />;
  };

  _createLeftColumnLayout = (width) => {
    const dividerVerticalRight = this.api.sessionSettings.data().isThemeLight
      ? styles.dividerLightVerticalRight
      : styles.dividerDarkVerticalRight;
    const dividerHorizontalBottom = this.api.sessionSettings.data().isThemeLight
      ? styles.dividerLightHorizontalBottom
      : styles.dividerDarkHorizontalBottom;

    const leftColTabsBg = this.api.sessionSettings.data().isThemeLight ? styles.areaBgLight : styles.areaBgDark;

    const handleTabChange = (_event, newValue) => {
      this.setState({
        leftColTabValue: newValue,
      });
    };

    const content = (
      <Grid container spacing={0} direction="column" alignItems="flex-start" sx={styles.mainContentLeftWrapper}>
        <Grid
          sx={{
            ...styles.quickActionsWrapper,
            ...dividerHorizontalBottom,
          }}
          item
        >
          <Grid sx={styles.quickActionsInnerWrapper}>
            <Grid sx={styles.actionTitleBox} item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography sx={styles.boxTitle} variant="h1" component="h2">
                    Quick actions
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <QuickActionsWrapper container spacing={0} direction="row" sx={styles.quickActionList}>
              <_CreateQuickActionList api={this.api} navigate={this.props.navigate} />
            </QuickActionsWrapper>
          </Grid>
        </Grid>
        <Grid item sx={{ ...styles.leftColTabs, ...leftColTabsBg }}>
          <Tabs value={this.state.leftColTabValue} onChange={handleTabChange} indicatorColor="primary">
            <Tab sx={styles.tabColItem} value="history" label="Machines" />
          </Tabs>
          <Box sx={{ fontSize: "12px", opacity: 0.6 }}>Machines sorted by finish time.</Box>
        </Grid>
        <Grid item sx={styles.historyWrapper}>
          <Grid
            sx={styles.historyInnerWrapper}
            style={{
              height: this.state.windowInnerHeight - 400,
            }}
          >
            {/* Tab Machines */}
            <Grid alignItems="stretch" hidden={this.state.leftColTabValue !== "history"}>
              {this._createHistoryList()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    if (width < _DashboardWithSidebar.APP_SIZING.CONTAINER_GF_MIN_WIDTH) {
      return (
        <CustomDrawer anchor="left" open={this.state.sideBarOpen} onClose={() => this._onShowSidebar(false)}>
          <Box sx={styles.sideBarContentWrapper}>{content}</Box>
        </CustomDrawer>
      );
    } else {
      const containerStyle = { ...styles.mainContentLeft, ...dividerVerticalRight };
      return (
        <Box
          component={Resizable}
          defaultSize={{
            width: "450px",
          }}
          id="test-123"
          bounds="window"
          sx={containerStyle}
          handleClasses={{ right: "handleResize" }}
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
        >
          <Grid item>{content}</Grid>
        </Box>
      );
    }
  };

  _createTopBarContainer = () => {
    const handleCloseWidgetAvatar = () => {
      this.refAvatarNotifications.current.handleClose();
    };

    return (
      <WidgetTopBarContainer
        style={{
          padding: "0px 20px",
          paddingResponsive: "0px 20px",
          justifyContent: "end",
        }}
        hasBorder={true}
      >
        <WidgetTopBarRightControlContainer>
          <UseQuery
            keyName={["check-notification"]}
            fn={() => this._checkNotification()}
            options={{ staleTime: 0, refetchInterval: 5000 }}
          >
            {(query) => {
              return (
                <WidgetAvatar
                  ref={this.refAvatarNotifications}
                  username={this._getUsername(this.props.auth)}
                  hasNotification={this.state.promotionNotifcationsData.length > 0 ? true : false}
                  promotionNotifcationData={this.state.promotionNotifcationsData}
                  onAbout={this.props.contextValue[0]}
                  onProfile={this.onProfile}
                  onLogout={() => this.onLogout()}
                >
                  <WidgetAvatarNotifications
                    key="widget-avatar-notifications"
                    promotionNotifcationData={this.state.promotionNotifcationsData}
                    closeMenu={handleCloseWidgetAvatar}
                    refreshFunc={this._checkNotification}
                  />
                </WidgetAvatar>
              );
            }}
          </UseQuery>
        </WidgetTopBarRightControlContainer>
      </WidgetTopBarContainer>
    );
  };

  _createRightColumnLayout = () => {
    const isThemeLight = this.api.sessionSettings.data().isThemeLight;

    //check log
    const rightColArea = {
      ...styles.rightColArea,
      ...(isThemeLight ? styles.areaBgLight : styles.areaBgDark),
    };

    return (
      <Box sx={{ p: 2.2 }}>
        <Grid item sx={styles.rightColTabWrapper}>
          <Box sx={styles.sideBarWrapper}>
            {this.state.windowInnerWidth < 1280 && (
              <Button
                variant="contained"
                size="small"
                startIcon={<icons.mui.ViewWeek />}
                onClick={() => this._onShowSidebar(!this.state.sideBarOpen)}
              >
                Sidebar
              </Button>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            ...rightColArea,
            overflowY: "scroll",
            overflowX: "hidden",
            maxHeight: "calc(100vh - 90px)",
          }}
        >
          <Grid
            container
            direction="column"
            sx={{
              display: "block",
              overflowX: "auto",
              overflowY: "hidden",
              "& > .page-wrapper > .MuiGrid-root": {
                maxWidth: "100%",
                overflowY: "hidden",
                mb: "20px",
              },
              "& > .page-wrapper > .MuiGrid-root:last-child": {
                mb: "0px",
              },
            }}
          >
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    );
  };

  _createProfileDialog = () => {
    return (
      <AuthContext.Consumer>
        {(auth) => (
          <DialogProfile
            ref={this.refDialogProfile}
            onOpen={() => {
              this.refAvatarNotifications.current.handleClose();
            }}
            data={auth.user}
          />
        )}
      </AuthContext.Consumer>
    );
  };

  // -------- Public: Reactjs render --------
  render() {
    return (
      <>
        {this._createProfileDialog()}
        {this._createLeftColumnLayout(this.state.windowInnerWidth)}
        <Grid item sx={styles.mainContentRight}>
          {this._createTopBarContainer()}
          {this._createRightColumnLayout()}
        </Grid>
      </>
    );
  }
}

const styles = {
  dividerDarkVerticalRight: {
    borderRight: "1px solid #2F2F2F",
  },
  dividerLightVerticalRight: {
    borderRight: "1px solid #E8E8E8",
  },
  dividerDarkHorizontalBottom: {
    borderBottom: "1px solid #2F2F2F",
  },
  dividerLightHorizontalBottom: {
    borderBottom: "1px solid #E8E8E8",
  },
  mainContentLeft: {
    "& .handleResize": {
      background: "none",
      width: "4px!important",
      right: "-2px!important",
    },
    "& .handleResize:hover": {
      background: "#2e2e2e",
    },
    order: 2,
    "@media (min-width: 1280px)": {
      minWidth: "230px",
      maxWidth: "50%",
      order: 1,
    },
  },
  mainContentRight: {
    flexGrow: 1,
    order: 1,
    width: "100%",
    "@media (min-width: 1280px)": {
      order: 2,
      width: "200px",
    },
  },
  mainContentLeftWrapper: {
    overflow: "hidden",
  },
  historyWrapper: {
    width: "100%",
    padding: "0px 10px",
  },
  historyInnerWrapper: {
    padding: "0px 10px",
    overflowX: "hidden",
    overflowY: "auto",
  },
  quickActionsWrapper: {
    display: "flex",
    width: "100%",
    minHeight: "auto",
    padding: "10px",
  },
  quickActionsInnerWrapper: {
    width: "100%",
    padding: "10px",
    overflowX: "auto",
    overflowY: "hidden",
    position: "relative",
  },
  quickActionList: {
    width: "100%!important",
    gap: "15px",
    "@media (min-width: 1280px)": {
      width: "405px!important",
    },
  },
  leftColTabs: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "1rem",
  },
  areaBgDark: {
    backgroundColor: "#000000",
  },
  areaBgLight: {
    backgroundColor: "#ffffff",
    boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px",
  },
  tabColItem: {
    minWidth: 130,
  },
  tabLabel: {
    fontSize: "15px",
    textTransform: "none",
  },
  rightColArea: {
    width: "100%",
    borderRadius: "6px",
  },
  rightColTabWrapper: {
    paddingLeft: "0px",
    "@media (min-width: 1280px)": {
      paddingLeft: "15px",
    },
  },
  actionTitleBox: {
    marginBottom: "20px!important",
    marginTop: "0px!important",
  },
  boxTitle: {
    fontSize: "1.2rem!important",
    fontWeight: "500!important",
  },
  sideBarContentWrapper: {
    maxWidth: "450px",
  },
  sideBarWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
};

const DashboardWithSidebar = withOutletContext(withAuthRedirect(withApiContext(_DashboardWithSidebar)));

export default withAuthContext(withPermissionsCheck(DashboardWithSidebar));
