import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Stack, styled } from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

const CustomAlert = styled(Alert)(() => ({
  fontSize: "14px",
  marginBottom: "10px",
}));

function DialogMoveToMachine({ data, machineData, alert, setAlert, isOpen, setIsOpen, isLoading, onSubmit }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [machine, setMachine] = useState("");

  const resetState = () => {
    setMachine("");
  };

  useEffect(() => {
    setIsDialogOpen(isOpen);
  }, [isOpen]);

  const onSubmitButton = async () => {
    const payload = {
      bfmId: data.bfId,
      currentMachineId: `${data.metadata.machineId}`,
      jobId: data.metadata.jobId,
      newMachineId: machine,
    };
    await onSubmit(payload);
  };

  const handleMachineChange = (event) => {
    setMachine(`${event.target.value}`);
  };

  return (
    <DialogCommonBase
      title="Move build file to another machine"
      isOpen={isDialogOpen}
      onClose={() => {
        setIsOpen(false);
        setAlert({ message: "", severity: "", component: "" });
        resetState();
      }}
      data-syklone="dialog-reschedule-to-machine"
    >
      {alert?.severity === "error" && alert?.component === "DialogMoveToMachine" ? (
        <CustomAlert severity={alert?.severity || "success"} sx={{ width: "100%" }}>
          {alert?.message}
        </CustomAlert>
      ) : null}
      <Stack direction="column" spacing={2}>
        <FormControl
          sx={{
            "& > :not(style)": { mt: 1, width: "450px" },
          }}
          noValidate
          autoComplete="off"
          data-syklone="family-name"
        >
          <InputLabel id="select-machine-box">Select machine</InputLabel>
          <Select
            labelId="select-machine-box"
            id="select-machine-reschedule"
            data-syklone="select-machine-reschedule"
            value={machine}
            label="Select machine"
            onChange={handleMachineChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {["R&D", "Under Review", "Obsolete", "Cancelled", "Released"].includes(data.status.name) &&
            data.status.name !== "Released"
              ? machineData
                  .filter((machine) => machine.id !== data.metadata.machineId)
                  .map((machine) => (
                    <MenuItem key={machine.id} value={machine.id}>
                      {machine.name}
                    </MenuItem>
                  ))
              : data.status.name === "Released"
              ? data.machine && data.machine.length > 0
                ? data.machine
                    ?.filter(
                      (machine) =>
                        machine.machineId !== null && machine.machineId !== data.metadata.machineId && !machine.onHold
                    )
                    .map((machine) => (
                      <MenuItem key={machine.machineId} value={machine.machineId}>
                        {machine.name}
                      </MenuItem>
                    ))
                : machineData
                    .filter((machine) => machine.id !== data.metadata.machineId)
                    .map((machine) => (
                      <MenuItem key={machine.id} value={machine.id}>
                        {machine.name}
                      </MenuItem>
                    ))
              : data.machine
                  ?.filter(
                    (machine) =>
                      machine.machineId !== null && machine.machineId !== data.metadata.machineId && !machine.onHold
                  )
                  .map((machine) => (
                    <MenuItem key={machine.machineId} value={machine.machineId}>
                      {machine.name}
                    </MenuItem>
                  ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          size="large"
          onClick={onSubmitButton}
          data-syklone="schedule-to-machine-button"
          disabled={machine.length === 0 || isLoading ? true : false}
        >
          {isLoading ? "Loading..." : "Reschedule"}
        </Button>
      </Stack>
    </DialogCommonBase>
  );
}

DialogMoveToMachine.propTypes = {
  data: PropTypes.object,
  machineData: PropTypes.array,
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  setIsOpen: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DialogMoveToMachine;
