import React, { useEffect, useState } from "react";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useMutation, useQueryClient } from "syklone/libraries/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";
import { arrayBufferBase64, addbase64Flag } from "syklone/common/utils.js";

const withPageSchedulingManual = (Component) => {
  const SchedulingPageManual = () => {
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();
    const { showSnackbar } = useAlertSnackbar();
    const [data, setData] = useState({ machines: [] });
    const sykloneApi = apiInstance.sykloneApi;

    // Dialog states
    const [buildStartDialogOpen, setBuildStartDialogOpen] = useState(false);
    const [buildStartDialogData, setBuildStartDialogData] = useState({});
    const [buildFileScheduleDialogOpen, setBuildFileScheduleDialogOpen] = useState(false);
    const [labelLoading, setIsLabelLoading] = useState(false);

    // Alert state
    const [alert, setAlert] = useState({ message: "", severity: "", snackBar: false, component: "" });

    // Loading state
    const [isChangePositionLoading, setIsChangePositionLoading] = useState(null);
    const [isUnscheduling, setIsUnscheduling] = useState(null);
    const [isPlayDialogLoading, setIsPlayDialogLoading] = useState(false);

    const {
      isLoading: isLoadingMachines,
      isSuccess: isSuccessMachines,
      data: machineData,
    } = useQuery({
      queryKey: ["machineData"],
      queryFn: () => sykloneApi.bcGetMachineData(),
      onError: () => {
        showSnackbar("Error getting machines.", "error");
      },
      refetchInterval: 10000,
    });

    const {
      isLoading: isLoadingSchedulesBuildFiles,
      isSuccess: isSuccessBuildFiles,
      data: scheduledBuildFiles,
    } = useQuery({
      queryKey: ["scheduledBuildFiles"],
      queryFn: () => sykloneApi.bcGetScheduledBuildfiles(),
      onError: () => {
        showSnackbar("Error getting scheduled build files.", "error");
      },
      refetchInterval: 3000,
    });

    const {
      isLoading: isLoadingReleasedBuildFiles,
      isSuccess: isSuccessReleased,
      data: releasedBuildFiles,
    } = useQuery({
      queryKey: ["releasedBuildFiles"],
      queryFn: () => sykloneApi.bcGetBfmReleased(),
      onError: () => {
        showSnackbar("Error getting released build files.", "error");
      },
    });

    const {
      // eslint-disable-next-line
      isLoading: isLoadingUrBuildFiles,
      isSuccess: isSuccessUrBuildFiles,
      data: urBuildFiles,
    } = useQuery({
      queryKey: ["urBuildFiles"],
      queryFn: () => sykloneApi.getBfmValidUnderReviewBuildFilesByMachineId(),
      onError: () => {
        showSnackbar("Error getting under review build files.", "error");
      },
    });

    const {
      // eslint-disable-next-line
      isLoading: isLoadingRdBuildFiles,
      isSuccess: isSuccessRdBuildFiles,
      data: rdBuildFiles,
    } = useQuery({
      queryKey: ["rdBuildFiles"],
      queryFn: () => sykloneApi.getBfmValidRndBuildFilesByMachineId(),
      onError: () => {
        showSnackbar("Error getting Research & development build files.", "error");
      },
    });

    const { mutate: onClickScheduleBuildFile, isLoading: isLoadingAddBuildFiles } = useMutation(
      async (data) => {
        let response = await sykloneApi.bcPostBfmSchedule(data);
        return response.data;
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["scheduledBuildFiles"] });
          queryClient.invalidateQueries({ queryKey: ["machineData"] });
          queryClient.invalidateQueries({ queryKey: ["releasedBuildFiles"] });
          queryClient.invalidateQueries({ queryKey: ["rdBuildFiles"] });
          queryClient.invalidateQueries({ queryKey: ["urBuildFiles"] });
          if (data.data.warning) {
            showSnackbar(data.data.warning, "warning");
          }
          let message = data.message || "Build file scheduled successfully.";
          showSnackbar(message, "success");
        },
        onError: (error) => {
          let errorMessage = error.detail || "Error with build file schedule.";
          showSnackbar(errorMessage, "error");
        },
      }
    );

    const onClickMachine = async (id) => {
      try {
        let response = await sykloneApi.bcGetEqipmentById(id);
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    };

    const getLabelSemantics = async (id) => {
      if (id === null || id === undefined) {
        return;
      }
      try {
        setIsLabelLoading(true);
        let response = await apiInstance.sykloneApi.servicePlatformRecipes.getJsonById(id);
        if (response) {
          setIsLabelLoading(false);
          return response.data.parts;
        }
      } catch (error) {
        console.log("error", error);
        setAlert({
          message: "Error getting build file part session.",
          severity: "error",
          snackBar: false,
          component: "DialogScheduleBuildFile",
        });
      }
    };

    const postBfmPrint = async (machineId) => {
      if (machineId === null || machineId === undefined) {
        return;
      }

      try {
        setIsPlayDialogLoading(true);
        let resposne = await sykloneApi.postBfmPrint(machineId);
        if (resposne) {
          queryClient.invalidateQueries({ queryKey: ["scheduledBuildFiles"] });
          showSnackbar("Event for running machine excuted successfully.", "success");
          setBuildStartDialogOpen(false);
          setIsPlayDialogLoading(false);
        }
        return resposne;
      } catch (error) {
        console.log("Error running printing taks: ", error);
        showSnackbar("Error running printing taks.", "error");
        setIsPlayDialogLoading(false);
      }
    };

    const postBfmPrintApproved = async (machineId) => {
      if (machineId === null || machineId === undefined) {
        return;
      }

      try {
        setIsPlayDialogLoading(true);
        let resposne = await sykloneApi.postBfmPrintApproved(machineId);
        if (resposne) {
          queryClient.invalidateQueries({ queryKey: ["scheduledBuildFiles"] });
          showSnackbar("Event for running machine excuted successfully.", "success");
          setBuildStartDialogOpen(false);
          setIsPlayDialogLoading(false);
        }
        return resposne;
      } catch (error) {
        console.log("Error running printing taks: ", error);
        showSnackbar("Error running printing taks.", "error");
        setIsPlayDialogLoading(false);
      }
    };

    const getBfmPrintStatusByTaskId = async (taskId) => {
      if (taskId === null || taskId === undefined) {
        return;
      }

      try {
        let response = await sykloneApi.getBfmPrintStatusByTaskId(taskId);
        return response;
      } catch (error) {
        console.log("Error", error);
      }
    };

    /* eslint-disable */
    const putChangeBuildFilePosition = async (bfId, jobId, machineId, order) => {
      const payload = {
        bfm_id: bfId,
        job_id: jobId,
        machine_id: machineId,
        order: order,
      };

      try {
        setIsChangePositionLoading({
          machineId: machineId,
          loading: true,
        });
        let response = await sykloneApi.bcPutBfmScheduledChangeBuildFilePosition(payload);
        const message = response?.data?.message ? response?.data?.message : "Build file position changed.";
        showSnackbar(message, "info");
        setIsChangePositionLoading(null);

        queryClient.invalidateQueries({ queryKey: ["scheduledBuildFiles"] });
        queryClient.invalidateQueries({ queryKey: ["machineData"] });
        queryClient.invalidateQueries({ queryKey: ["releasedBuildFiles"] });
        queryClient.invalidateQueries({ queryKey: ["rdBuildFiles"] });
        queryClient.invalidateQueries({ queryKey: ["urBuildFiles"] });
      } catch (error) {
        console.log("Error changing buildfile position: ", error);
        const message = error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error changing buildfile position.";
        showSnackbar(message, "error");
      }
    };

    const putUnscheduleBuildFile = async (bfId, jobId, machineId) => {
      const payload = {
        bfm_id: bfId,
        job_id: jobId,
        machine_id: machineId.toString(),
      };

      try {
        setIsUnscheduling({
          jobId: jobId,
          loading: true,
        });
        let response = await sykloneApi.bcPutBfmUnschedule(payload);
        const message = response?.data?.message ? response?.data?.message : "Build file is unscheduled.";
        showSnackbar(message, "info");
        setIsUnscheduling(null);

        queryClient.invalidateQueries({ queryKey: ["scheduledBuildFiles"] });
        queryClient.invalidateQueries({ queryKey: ["machineData"] });
        queryClient.invalidateQueries({ queryKey: ["releasedBuildFiles"] });
        queryClient.invalidateQueries({ queryKey: ["rdBuildFiles"] });
        queryClient.invalidateQueries({ queryKey: ["urBuildFiles"] });
      } catch (error) {
        console.log("Error changing buildfile position: ", error);
        const message = error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error unscheduling build file.";
        showSnackbar(message, "error");
      }
    };

    const getSnapshotBlobById = async (id) => {
      try {
        const responseSnapshot = await apiInstance.sykloneApi.serviceSnapshots.getBlob(id);
        return addbase64Flag(arrayBufferBase64(responseSnapshot.data));
      } catch (error) {
        console.log("Error getting snapshot: ", error);
        const message = error?.response?.data?.message ? error?.response?.data?.message : "Error getting snapshot.";
        showSnackbar(message, "error");
      }
    };

    const getBfmPrintCurrentStatusByMachineId = async (machineId) => {
      try {
        const response = await apiInstance.sykloneApi.getBfmPrintCurrentStatusByMachineId(machineId);
        return response;
      } catch (error) {
        const message = error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error getting machine status.";
        showSnackbar(message, "error");
      }
    };

    const { mutate: putRescheduleToAnotherMachine, isLoading: isRescheduleLoading } = useMutation(
      async (data) => {
        const payload = {
          bfm_id: data.bfmId,
          current_machine_id: Number(data.currentMachineId),
          job_id: data.jobId,
          new_machine_id: Number(data.newMachineId),
          // order: null
        };

        let response = await sykloneApi.putBfmScheduledMoveBuildToNewQueue(payload);
        return response.data;
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["scheduledBuildFiles"] });
          queryClient.invalidateQueries({ queryKey: ["machineData"] });
          queryClient.invalidateQueries({ queryKey: ["releasedBuildFiles"] });
          queryClient.invalidateQueries({ queryKey: ["rdBuildFiles"] });
          queryClient.invalidateQueries({ queryKey: ["urBuildFiles"] });
          if (data.warning) {
            showSnackbar(data.warning, "warning");
          }
          let message = data.data || "Build file rescheduled successfully.";
          showSnackbar(message, "success");
        },
        onError: (error) => {
          setAlert({
            message: error.response.data.detail,
            severity: "error",
            snackBar: false,
            component: "DialogMoveToMachine",
          });
        },
      }
    );

    const { mutate: postBfmSwitchLockByMachineId, isLoading: isLockingMachine } = useMutation(
      async (data) => {
        const { machineId } = data;
        let response = await sykloneApi.postBfmSwitchLockByMachineId(machineId);
        return response.data;
      },
      {
        onSuccess: (data) => {
          const message = data?.message ? data?.message : "Machine locked.";
          showSnackbar(message, "success");
        },
        onError: (error) => {
          showSnackbar("Error locking the machine.", "error");
        },
      }
    );

    const { mutate: postBfmSwitchUnlockByMachineId, isLoading: isUnLockingMachine } = useMutation(
      async (data) => {
        const { machineId } = data;
        let response = await sykloneApi.postBfmSwitchUnlockByMachineId(machineId);
        return response.data;
      },
      {
        onSuccess: (data) => {
          const message = data?.message ? data?.message : "Machine locked.";
          showSnackbar(message, "success");
        },
        onError: (error) => {
          showSnackbar("Error locking the machine.", "error");
        },
      }
    );

    useEffect(() => {
      if (
        isSuccessMachines &&
        releasedBuildFiles &&
        isSuccessBuildFiles &&
        isSuccessRdBuildFiles &&
        isSuccessUrBuildFiles &&
        scheduledBuildFiles
      ) {
        setData({
          machines: machineData.data.data.map((machine) => {
            if (scheduledBuildFiles?.data.data === null || scheduledBuildFiles?.data.data === undefined) {
              showSnackbar("Error getting scheduled build files.", "error");
            }
            const machineScheduledBuildFiles =
              scheduledBuildFiles?.data.data
                ?.flatMap((scheduled) => scheduled.machine)
                .find((schMachine) => schMachine.machineId === String(machine.id))?.items || [];

            let allBfTypes = [...rdBuildFiles.data.data, ...urBuildFiles.data.data, ...releasedBuildFiles.data.data];

            const buildFiles = machineScheduledBuildFiles
              .map((scheduledBuildFile) => {
                const filteredBuildFile = allBfTypes.find(
                  (buildFile) => buildFile.id === scheduledBuildFile.buildFileId
                );

                if (filteredBuildFile) {
                  const updatedMachineValidity = filteredBuildFile.machineValidity.map((validity) => {
                    const matchedMachine = machineData.data.data.find((m) => m.name === validity.name);
                    return {
                      ...validity,
                      machineId: matchedMachine ? matchedMachine.id : null,
                      lotId: scheduledBuildFile.lotId,
                    };
                  });

                  return {
                    id: filteredBuildFile.id,
                    typeName: "Build file",
                    itemTitle: filteredBuildFile.name,
                    itemSubTitlePrefix: "Created by",
                    itemSubTitle: filteredBuildFile.lifecycle[0].user,
                    status: {
                      type: machine.is_qualified_for_production,
                      active: true,
                      name: filteredBuildFile.status,
                    },
                    metadata: {
                      jobId: scheduledBuildFile.jobId,
                      buildFileId: scheduledBuildFile.buildFileId,
                      machineId: machine.id,
                      lotId: scheduledBuildFile.lotId,
                      lock: scheduledBuildFile.lock,
                      isScheduled: scheduledBuildFile["is_scheduled"],
                      message: scheduledBuildFile.message,
                      initiationStatus: scheduledBuildFile.initiationStatus,
                      initiationNotification: scheduledBuildFile.initiationNotification,
                    },
                    machineValidity: updatedMachineValidity,
                  };
                }
                return null;
              })
              .filter((buildFile) => buildFile !== null);

            return {
              machine: {
                id: machine.id,
                typeName: "Machine",
                itemTitle: machine.name,
                itemSubTitlePrefix: "Domain",
                itemSubTitle: machine.domain_name,
                status: {
                  type: machine.is_qualified_for_production,
                  active: true,
                  name: machine.is_qualified_for_production
                    ? "Qualified for production"
                    : "Not qualified for production",
                },
              },
              buildFiles,
            };
          }),
        });
      }
    }, [
      machineData,
      releasedBuildFiles,
      scheduledBuildFiles,
      isSuccessBuildFiles,
      isSuccessUrBuildFiles,
      isSuccessRdBuildFiles,
    ]);

    return (
      <Component
        alert={alert}
        setAlert={setAlert}
        data={data}
        machineData={isSuccessMachines ? machineData.data.data : []}
        isLoading={{
          isLoadingMachines,
          isLoadingSchedulesBuildFiles,
          isLoadingReleasedBuildFiles,
          isChangePositionLoading,
          isUnscheduling,
          isLoadingAddBuildFiles,
          isRescheduleLoading,
          isPlayDialogLoading,
          isLockingMachine,
          isUnLockingMachine,
        }}
        releasedBuildFiles={isSuccessReleased ? releasedBuildFiles.data.data : []}
        urBuildFiles={isSuccessUrBuildFiles ? urBuildFiles.data.data : []}
        rdBuildFiles={isSuccessRdBuildFiles ? rdBuildFiles.data.data : []}
        onClickMachine={onClickMachine}
        onClickScheduleBuildFile={onClickScheduleBuildFile}
        putChangeBuildFilePosition={putChangeBuildFilePosition}
        putUnscheduleBuildFile={putUnscheduleBuildFile}
        getBfmPrintStatusByTaskId={getBfmPrintStatusByTaskId}
        getBfmPrintCurrentStatusByMachineId={getBfmPrintCurrentStatusByMachineId}
        dialogFn={{
          setBuildStartDialogOpen,
          setBuildStartDialogData,
          setBuildFileScheduleDialogOpen,
          getLabelSemantics,
          postBfmPrint,
          getSnapshotBlobById,
          putRescheduleToAnotherMachine,
          postBfmPrintApproved,
          postBfmSwitchLockByMachineId,
          postBfmSwitchUnlockByMachineId,
        }}
        dialogState={{
          buildStartDialogOpen,
          buildStartDialogData,
          buildFileScheduleDialogOpen,
          labelLoading,
        }}
      />
    );
  };
  SchedulingPageManual.displayName = "SchedulingPageManual";
  SchedulingPageManual.propTypes = {};
  return SchedulingPageManual;
};

export default withPageSchedulingManual;
