import PropTypes from "prop-types";
import React from "react";

// imports: syklone
import { useQueries, useQuery } from "syklone/libraries/index.js";
import { Box, Grid, Typography } from "syklone/ui/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { WidgetRecentBuildFileUpdates, WidgetBfPromotionList } from "../../widgets/index.js";

// -------- PagePartPlatform --------
function PagePartPlatform(props) {
  const apiInstance = useApiContext();
  const serviceBfm = apiInstance.sykloneApi.serviceBfm;
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  // -------- API requests --------
  const promotionQueryData = useQueries({
    queries: [
      {
        queryKey: ["dataRdCollection", 1],
        queryFn: async () => {
          const response = await serviceBfm.getResearchAndDevelopment();
          return response.data;
        },
      },
      {
        queryKey: ["dataUnderReview", 2],
        queryFn: async () => {
          const response = await serviceBfm.getUnderReview();
          return response.data;
        },
      },
      {
        queryKey: ["dataReleased", 3],
        queryFn: async () => {
          const response = await serviceBfm.getReleased();
          return response.data;
        },
      },
    ],
  });

  const promotionCollectionTypesQuery = useQuery(
    ["promotionCollectionTypes"],
    async () => {
      const response = await serviceAuth.getBuildFileCollectionTypes();
      if (response.data.options && Array.isArray(response.data.options)) {
        const TAB_LABELS = ["R&D Collection", "Under review", "Released"];
        return TAB_LABELS.map((item, index) => {
          const option = response.data.options[index];
          return {
            value: option !== null ? option : null,
            label: item,
          };
        });
      } else {
        return [];
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const promotionApprovalTypesQuery = useQuery(
    ["promotionApprovalTypes"],
    async () => {
      const response = await serviceAuth.getBuildFileApprovalTypes();
      if (response.data.options && Array.isArray(response.data.options)) {
        return response.data.options.map((item) => {
          return {
            value: item[0],
            label: item[1],
          };
        });
      } else {
        return [];
      }
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return (
    <Box sx={{ padding: 2.5 }} className="page-wrapper">
      <WidgetRecentBuildFileUpdates history={props.history} navigate={props.navigate} />
      <Grid item>
        <Grid container spacing={0}>
          <Grid sx={{ marginBottom: "10px", marginTop: "10px" }} item>
            <Typography
              sx={{
                fontSize: "1.2rem!important",
                fontWeight: "500!important",
              }}
              variant="h1"
              component="h2"
            >
              Build file promotion
            </Typography>
          </Grid>
          <WidgetBfPromotionList
            promotionQueryData={promotionQueryData}
            promotionCollectionQueryData={promotionCollectionTypesQuery}
            promotionApprovalTypesQueryData={promotionApprovalTypesQuery}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

PagePartPlatform.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  navigate: PropTypes.func,
};

export default PagePartPlatform;
