import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// imports: syklone
import { Box, icons, IconButton, TextField, Tooltip, DataGrid, Tabs, Tab } from "syklone/ui/index.js";
import { fileDownload, useQueryClient } from "syklone/libraries/index.js";
import { useApiContext, usePermissionChecks } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

// imports: local
import { WidgetBfOnHoldDialog, WidgetBfPromotionDialog, WidgetEditDialog, WidgetPopoverMenu } from "../index.js";
import { DialogCancelBuildFile, DialogElectronicSignature } from "../../dialogs/index.js";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function QuickSearchToolbar({ clearSearch, onChange, value }) {
  return (
    <Box
      sx={(theme) => ({
        position: "absolute",
        right: 0,
        top: -40,
        borderLeft: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
      })}
    >
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <icons.mui.Search fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <icons.mui.Cancel fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          "& .MuiInputBase-root": {
            height: 40,
            padding: "0px 10px",
            "& > .MuiSvgIcon-root": {
              mr: 0.5,
            },
          },
          "& .MuiInput-underline:before": {
            borderBottom: 0,
            borderColor: "divider",
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0;
};

function findCreatedRow(array) {
  return array.find((element) => element.step === "created");
}

const transformDataTemplate = (item) => {
  return {
    id: item.id,
    createdBy: findCreatedRow(item.lifecycle).user,
    approvedBy: item.lastApprover,
    status: item.status,
    comments: item.comments && item.comments.length > 0 ? item.comments[0] : "",
    productDetails: item.name,
    machineValidation:
      item.machineValidity && item.machineValidity?.length > 0
        ? item.machineValidity?.map((item) => {
            return item.name;
          })
        : "N/A",
    customFields: isObjectEmpty(item.metadata?.customFields)
      ? "N/A"
      : JSON.stringify(item.metadata?.customFields || "")
          .replace(/]|[[]|{|}|"|"/g, "")
          .split(",")
          .join(", "),
    productId: item.productId,
    onHold: item.onHoldSites,
  };
};

function WidgetBfPromotionList({ promotionQueryData, promotionCollectionQueryData, promotionApprovalTypesQueryData }) {
  const [dataRdCollection, dataUnderReview, dataReleased] = promotionQueryData;
  const { data: promotionCollectionData } = promotionCollectionQueryData;
  const { data: promotionApprovalTypesData } = promotionApprovalTypesQueryData;
  const loading = dataRdCollection.isLoading || dataReleased.isLoading || dataUnderReview.isLoading;

  const apiInstance = useApiContext();
  const serviceBfm = apiInstance.sykloneApi.serviceBfm;
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  const navigate = useNavigate();
  const { showSnackbar } = useAlertSnackbar();
  const { checkElementVisibility } = usePermissionChecks();

  const showNoClsFilesSnackbar = () => {
    showSnackbar("No CLS files found for build file", "warning");
  };

  const queryClient = useQueryClient();

  // Tabs definition
  const [tabValue, setTabValue] = useState(null);

  const isPromotionCollectionLoaded = promotionCollectionQueryData.isSuccess && tabValue;

  // Tabs data
  const [transformedDataRd, setTransformedDataRd] = useState([]);
  const [transformedDataUnderReview, setTransformedDataUnderReview] = useState([]);
  const [transformedDataReleased, setTransformedDataReleased] = useState([]);

  // Dialog definitions
  const [anchorEl, setAnchorEl] = useState(null);
  const [bfId, setBfId] = useState(null);

  // Datagrid column visibility model
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    productId: false,
    onHold: false,
  });

  const dialogCancleBuildFileRef = useRef(null);
  const dialogCancleBuildFileElectronicRef = useRef(null);

  useEffect(() => {
    setColumnVisibilityModel({
      productId: tabValue === "RELEASED",
      onHold: false,
    });
  }, [tabValue]);

  // Create action menu
  const actionMenu = (params) => {
    const isDisabledStatus = params.row.status === "Cancelled" || params.row.status === "Obsolete";

    return {
      menuName: getButtonStateLabel(params.row.id) ? "Loading..." : "Actions",
      menuItems: [
        {
          name: "Details",
          action: () => {
            const buildFileId = params.row.id;
            navigate("/dashboard/part-platform/viewer/part-viewer", {
              state: {
                buildFileId: buildFileId,
                buildFileType: tabValue,
              },
            });
          },
        },
        {
          name: "Edit machines",
          action: () => onClickEditButton(params.row.productDetails, params.row.id, "machines", params.row.onHold),
          disabled:
            isDisabledStatus ||
            (tabValue === "RESEARCH_AND_DEVELOPMENT"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesResearchAndDevelopmentEditMachines)
              : tabValue === "UNDER_REVIEW"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesUnderReviewEditMachines)
              : tabValue === "RELEASED"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesReleasedEditMachines)
              : false),
        },
        {
          name: "Edit metadata",
          action: () => onClickEditButton(params.row.productDetails, params.row.id, "metadata"),
          disabled:
            isDisabledStatus ||
            (tabValue === "RESEARCH_AND_DEVELOPMENT"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesResearchAndDevelopmentEditMetadata)
              : tabValue === "UNDER_REVIEW"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesUnderReviewEditMetadata)
              : tabValue === "RELEASED"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesReleasedEditMetadata)
              : false),
        },
        {
          name: "Download",
          action: () => downloadCls(params.row.id, params.row.productDetails),
          disabled:
            isDisabledStatus ||
            (tabValue === "RESEARCH_AND_DEVELOPMENT"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesResearchAndDevelopmentDownloadCls)
              : tabValue === "UNDER_REVIEW"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesUnderReviewDownloadCls)
              : tabValue === "RELEASED"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesReleasedDownloadCls)
              : false),
        },
        {
          name: "Promote build file",
          action: (event) => onClickPromotionButton(event, params.row.id, tabValue, params.row.productDetails),
          isHidden: tabValue === promotionCollectionData[2].value ? true : false,
          disabled:
            isDisabledStatus ||
            (tabValue === "RESEARCH_AND_DEVELOPMENT"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesResearchAndDevelopmentPromoteBuildFile)
              : tabValue === "UNDER_REVIEW"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesUnderReviewPromoteBuildFile)
              : false),
        },
        {
          name: "Set on hold",
          action: () => onClickOnHoldButton(params.row.id, params.row.productDetails, params.row.onHold),
          isHidden: false,
          disabled:
            isDisabledStatus ||
            (tabValue === "RESEARCH_AND_DEVELOPMENT"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesResearchAndDevelopmentOnHold)
              : tabValue === "UNDER_REVIEW"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesUnderReviewOnHold)
              : tabValue === "RELEASED"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesReleasedOnHold)
              : false),
        },
        {
          name: tabValue === "RELEASED" ? "Obsolete build file" : "Cancel build file",
          action: () => onClickCancel(params.row.id, tabValue),
          disabled:
            isDisabledStatus ||
            (tabValue === "RESEARCH_AND_DEVELOPMENT"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesResearchAndDevelopmentCancelBuildFile)
              : tabValue === "UNDER_REVIEW"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesUnderReviewCancelBuildFile)
              : tabValue === "RELEASED"
              ? !checkElementVisibility(PermissionVisualElements.buildFilesReleasedCancelBuildFile)
              : false),
          type: "error",
        },
      ],
    };
  };

  // imports: localgrid columns definition
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      hide: true,
    },
    {
      field: "productDetails",
      headerName: "Product details",
      minWidth: 140,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "productId",
      headerName: "Product ID",
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: "machineValidation",
      headerName: "Machine validity",
      width: 195,
      disableColumnMenu: true,
    },
    {
      field: "comments",
      headerName: "Comments",
      sortable: false,
      width: 80,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip placement="right" title={params.value}>
          <IconButton size="small">
            <icons.mui.Info />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "customFields",
      headerName: "Custom fields",
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      width: 85,
      disableColumnMenu: true,
    },
    {
      field: "approvedBy",
      headerName: "Approved by",
      width: 85,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      disableColumnMenu: true,
    },
    {
      field: "onHold",
      headerName: "onHold",
      width: 80,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 110,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <WidgetPopoverMenu
              data={actionMenu(params)}
              id={
                tabValue === "RESEARCH_AND_DEVELOPMENT"
                  ? `rnd-bfm`
                  : tabValue === "UNDER_REVIEW"
                  ? `ur-bfm`
                  : tabValue === "RELEASED"
                  ? `released-bfm`
                  : ""
              }
              loading={getButtonStateLabel(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const createTransformedData = (inputData, type) => {
    const data = inputData?.data || inputData;

    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    if (type === promotionCollectionData[0].value) {
      return data.map((item) => {
        setTransformedDataRd((prevState) => [...prevState, transformDataTemplate(item)]);
      });
    }
    if (type === promotionCollectionData[1].value) {
      return data.map((item) => {
        setTransformedDataUnderReview((prevState) => [...prevState, transformDataTemplate(item)]);
      });
    }
    if (type === promotionCollectionData[2].value) {
      return data.map((item) => {
        setTransformedDataReleased((prevState) => [...prevState, transformDataTemplate(item)]);
      });
    }
  };

  // Handle tab data
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [dataRows, setDataRows] = useState([]);

  useEffect(() => {
    // This will execute when the query successfully fetches data
    if (promotionCollectionQueryData.isSuccess && promotionCollectionData && promotionCollectionData.length > 0) {
      // Set the tabValue to the value of the first item in the fetched data
      setTabValue(promotionCollectionData[0].value);
    }
  }, [promotionCollectionQueryData.isSuccess, promotionCollectionData]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case promotionCollectionData[1].value:
        setDataRows(transformedDataUnderReview);
        setRows(transformedDataUnderReview);
        break;
      case promotionCollectionData[2].value:
        setDataRows(transformedDataReleased);
        setRows(transformedDataReleased);
        break;
      default:
        setDataRows(transformedDataRd);
        setRows(transformedDataRd);
    }
  };

  useEffect(() => {
    if (promotionCollectionQueryData.isSuccess) {
      setTransformedDataRd([]);
      setTransformedDataUnderReview([]);
      setTransformedDataReleased([]);
      if (dataRdCollection.data) {
        createTransformedData(dataRdCollection.data, promotionCollectionData[0].value);
      }
      if (dataUnderReview.data) {
        createTransformedData(dataUnderReview.data, promotionCollectionData[1].value);
      }
      if (dataUnderReview.data) {
        createTransformedData(dataReleased.data, promotionCollectionData[2].value);
      }
      requestSearch("");
    }
  }, [dataRdCollection, dataUnderReview, dataReleased, promotionCollectionQueryData.isSuccess]);

  useEffect(() => {
    if (promotionCollectionQueryData.isSuccess) {
      if (tabValue === promotionCollectionData[0].value) {
        setRows(transformedDataRd);
        setDataRows(transformedDataRd);
      }
      if (tabValue === promotionCollectionData[1].value) {
        setRows(transformedDataUnderReview);
        setDataRows(transformedDataUnderReview);
      }
      if (tabValue === promotionCollectionData[2].value) {
        setRows(transformedDataReleased);
        setDataRows(transformedDataReleased);
      }
    }
  }, [transformedDataRd, promotionCollectionQueryData.isSuccess]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = dataRows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };

  // -------- Getters/Setters --------
  const getAnchorElement = () => {
    return anchorEl;
  };
  const setAnchorElement = (event) => {
    setAnchorEl(event);
  };
  const setBuildFileId = (id) => {
    setBfId(id);
  };

  //-------- Slots --------
  const onClickPromotionButton = (event, id, _tabValue, productName) => {
    getGroups();
    setBuildFileId(id);
    setAnchorElement(event.currentTarget);
    setProductName(productName);
  };

  const onClickOnHoldButton = (id, productName, onHold) => {
    setBuildFileId(id);
    setOnHoldDialogOpen(true);
    setProductName(productName);
    setOnHoldState(onHold);
  };

  // Cancel dialog

  const onClickCancel = (id) => {
    if (tabValue === "RESEARCH_AND_DEVELOPMENT") {
      dialogCancleBuildFileRef.current.setIsDialogOpen(true);
    }

    if (tabValue === "UNDER_REVIEW" || tabValue === "RELEASED") {
      dialogCancleBuildFileElectronicRef.current.setIsDialogOpen(true);
    }

    setBuildFileId(id);
  };

  const fnDialogCancel = async () => {
    try {
      let response;

      if (tabValue === "RESEARCH_AND_DEVELOPMENT") {
        response = await serviceBfm.postResearchAndDevelopmentCancelByBuildFileId(bfId);
      }

      if (tabValue === "UNDER_REVIEW") {
        response = await serviceBfm.postUnderReviewCancelByBuildFileId(bfId);
      }

      if (tabValue === "RELEASED") {
        response = await serviceBfm.postReleasedObsoleteByBuildFileId(bfId);
      }

      if (tabValue === "RESEARCH_AND_DEVELOPMENT") {
        queryClient.invalidateQueries("dataRdCollection");
      } else if (tabValue === "UNDER_REVIEW") {
        queryClient.invalidateQueries("dataUnderReview");
      } else {
        queryClient.invalidateQueries("dataReleased");
      }

      queryClient.invalidateQueries("recentBuildFiles");

      const message = response?.data?.message
        ? response?.data?.message
        : tabValue === "RELEASED"
        ? "Build file obsolete."
        : "Build file canceled.";

      dialogCancleBuildFileRef.current.setIsDialogOpen(false);
      dialogCancleBuildFileElectronicRef.current.setIsDialogOpen(false);

      showSnackbar(message, "success");
    } catch (error) {
      const message = "Error processing build file.";
      showSnackbar(message, "error");
      console.log("error", error);

      const messageDialog = error || "An error occurred while updating.";
      dialogCancleBuildFileElectronicRef.current?.setResponseMessage({ text: messageDialog, type: "error" });
      return; // Exit the function early on error
    }
  };

  const onClosePromoteDialog = () => {
    setAnchorElement(null);
  };

  //-------- API Requests --------
  const [promotionGroupData, setPromotionGroupData] = useState([]);

  const getGroups = async () => {
    const response = await serviceAuth.getAdminGroupsActive();
    setPromotionGroupData(response.data.data);
  };

  const checkClsAvailability = async (id, productDetails) => {
    try {
      let response;
      switch (tabValue) {
        case promotionCollectionData[0].value:
          response = await serviceBfm.getResearchAndDevelopmentClsByBuildFileId(id);
          break;
        case promotionCollectionData[1].value:
          response = await serviceBfm.getUnderReviewClsByBuildFileId(id);
          break;
        case promotionCollectionData[2].value:
          response = await serviceBfm.getReleasedClsByBuildFileId(id);
          break;
        default:
          response = null;
      }
      return response.data.files && response.data.files.length !== 0
        ? getClsFiles(id, productDetails)
        : showNoClsFilesSnackbar();
    } catch (error) {
      alert(`${error}`);
    }
  };

  const getClsFiles = async (id, productDetails) => {
    try {
      let response;
      const headers = {
        responseType: "blob",
      };
      switch (tabValue) {
        case promotionCollectionData[0].value:
          response = await serviceBfm.getResearchAndDevelopmentClsBlobByBuildFileId(id, headers);
          break;
        case promotionCollectionData[1].value:
          response = await serviceBfm.getUnderReviewClsBlobByBuildFileId(id, headers);
          break;
        case promotionCollectionData[2].value:
          response = await serviceBfm.getReleasedClsBlobByBuildFileId(id, headers);
          break;
        default:
          response = null;
      }
      fileDownload(response.data, `cls_${productDetails}.zip`);
    } catch (error) {
      alert(`${error}`);
    }
  };

  // Edit button
  const [onHoldDialogOpen, setOnHoldDialogOpen] = useState(false);
  const [onHoldState, setOnHoldState] = useState(null);

  // Edit button
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editComponent, setEditComponent] = useState(null);
  const [productName, setProductName] = useState("");

  /*
  const getEditMetaData = () => {
    return editMetaData;
  };
  */

  const onClickEditButton = async (productName, id, component, onHold) => {
    setEditDialogOpen(true);
    setEditComponent(component);
    setBuildFileId(id);
    setProductName(productName);
    setOnHoldState(onHold);
  };

  //Download button CLS
  const [isDownloading, setIsDownloading] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState([]);

  const getButtonStateLabel = (id) => {
    return id === clickedButtonId.find((element) => element === id) && isDownloading ? true : false;
  };

  const downloadCls = async (id, productDetails) => {
    setClickedButtonId((prevState) => [...prevState, id]);
    setIsDownloading(true);
    await checkClsAvailability(id, productDetails);
    setClickedButtonId([]);
    setIsDownloading(false);
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const deleteMachineValidity = async (buildFileId, queryParams, tab) => {
    try {
      setIsDeleting(true);
      let response;
      switch (tab) {
        case "RESEARCH_AND_DEVELOPMENT":
          response = await serviceBfm.deleteResearchAndDevelopmentMachineValidityByBuildFileId(
            buildFileId,
            queryParams
          );
          break;
        case "UNDER_REVIEW":
          response = await serviceBfm.deleteUnderReviewMachineValidityByBuildFileId(buildFileId, queryParams);
          break;
        case "RELEASED":
          response = await serviceBfm.deleteReleasedMachineValidityByBuildFileId(buildFileId, queryParams);
          break;
        default:
          response = null;
      }

      queryClient.invalidateQueries("buildFileData");
      const message = response?.data?.message ? response?.data?.message : "Machine validity removed successfully.";
      showSnackbar(message, "info");
      setIsDeleting(false);
    } catch (error) {
      const message = "Error deleting the record.";
      showSnackbar(message, "error");
      console.log("Error deleting the record.", error);
    }
  };

  return (
    <>
      <WidgetBfPromotionDialog
        getAnchorElement={getAnchorElement}
        onClosePromoteDialog={onClosePromoteDialog}
        getProductName={productName}
        buildFileId={bfId}
        promotionGroupData={promotionGroupData}
        promotionApprovalTypesData={promotionApprovalTypesData}
        tabValue={tabValue}
      />

      <DialogCancelBuildFile
        ref={dialogCancleBuildFileRef}
        message={
          tabValue === "RESEARCH_AND_DEVELOPMENT"
            ? "Confirm cancelling this build file. This action permanently deletes this build file. This action is not reversible."
            : tabValue === "UNDER_REVIEW"
            ? "Confirm changing Build File status to Cancelled. This action permanently sets the build file status to Cancelled and prevents printing on qualified machines. This action is not reversible."
            : tabValue === "RELEASED"
            ? "Confirm changing Build File status to Obsolete. This action permanently sets the build file status to Obsolete and prevents printing on qualified machines. This action is not reversible."
            : ""
        }
        title="Cancel build file"
        fn={fnDialogCancel}
      />

      <DialogElectronicSignature
        ref={dialogCancleBuildFileElectronicRef}
        onSubmitSuccess={fnDialogCancel}
        title={"Cancel build file"}
        subtitle={
          tabValue === "UNDER_REVIEW"
            ? "Confirm changing Build File status to Cancelled. This action permanently sets the build file status to Cancelled and prevents printing on qualified machines. This action is not reversible."
            : tabValue === "RELEASED"
            ? "Confirm changing Build File status to Obsolete. This action permanently sets the build file status to Obsolete and prevents printing on qualified machines. This action is not reversible."
            : ""
        }
      />

      <WidgetEditDialog
        promotionCollectionData={promotionCollectionData}
        tabValue={tabValue}
        buildFileId={bfId}
        isDialogOpen={editDialogOpen}
        setIsDialogOpen={setEditDialogOpen}
        productName={productName}
        deleteMachineValidity={deleteMachineValidity}
        isDeleting={isDeleting}
        component={editComponent}
        onHoldState={onHoldState}
      />

      <WidgetBfOnHoldDialog
        isDialogOpen={onHoldDialogOpen}
        setIsDialogOpen={setOnHoldDialogOpen}
        buildFileName={productName}
        buildFileId={bfId}
        buildFileStatus={tabValue}
        onHoldState={onHoldState}
      />

      <Box sx={styles.tabWrapper}>
        {isPromotionCollectionLoaded && (
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {promotionCollectionData.map((item) => {
              return (
                <Tab
                  key={item.label}
                  sx={styles.tabColItem}
                  value={item.value}
                  label={
                    <Box component="span" sx={styles.tabLabel}>
                      {item.label}
                    </Box>
                  }
                />
              );
            })}
          </Tabs>
        )}
      </Box>
      <Box
        sx={{ position: "relative", height: "calc(100vh - 670px)", width: "100%" }}
        data-syklone="buildfiles-table-wrapper"
      >
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          loading={loading === "loading" ? true : false}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(""),
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          sx={(theme) => ({
            position: "absolute",
            height: "100%",
            width: "100%",
            fontSize: 13,
            "&.MuiDataGrid-root": {
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
            },
            ".MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
          })}
        />
      </Box>
    </>
  );
}

WidgetBfPromotionList.propTypes = {
  promotionQueryData: PropTypes.array,
  promotionCollectionQueryData: PropTypes.object,
  promotionApprovalTypesQueryData: PropTypes.object,
};

const styles = {
  tabWrapper: {
    display: "flex",
    width: "100%",
    borderBottom: (theme) => `1px solid ${theme.palette.mode === "dark" ? "#2F2F2F" : "#E8E8E8"}`,
  },
};

export default WidgetBfPromotionList;
