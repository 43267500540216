import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  arrayMove,
  closestCenter,
  DndContext,
  horizontalListSortingStrategy,
  KeyboardSensor,
  PointerSensor,
  restrictToHorizontalAxis,
  SortableContext,
  sortableKeyboardCoordinates,
  useSensor,
  useSensors,
} from "syklone/libraries/index.js";
import { Box, Chip, icons, IconButton, styled } from "syklone/ui/index.js";

// imports: local
import { WidgetScheduleRowItem, WidgetScheduleRowPlay } from "../index.js";
import { BuildFileSeparator, BuildFileSeparatorLastChild } from "./assets/img/index.js";

const BoxContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#111111" : "#f8f8f8",
  borderRadius: "3px",
  display: "flex",
  width: "100%",
  alignItems: "center",
  paddingLeft: 0,
  paddingRight: ".5rem",
  paddingTop: ".5rem",
  paddingBottom: ".5rem",
}));

const BoxMachine = styled(Box)({
  minWidth: "470px",
});

const BoxPlayButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  minWidth: "135px",
  marginRight: 15,
  textAlign: "center",
  height: "112px",
});

const BoxBuildFilesWrapper = styled(Box)({
  display: "flex",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
});

const BoxBuildFiles = styled(Box)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row-reverse",
  margin: "0px 5px",
  overflowX: "scroll",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const BoxWidgetScheduleRowItemWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  "&:before": {
    content: "''",
    width: 21,
    height: 9,
    backgroundImage: `url(${BuildFileSeparator})`,
  },
  "&:first-of-type": {
    "&:after": {
      content: "''",
      width: 15,
      height: 9,
      backgroundImage: `url(${BuildFileSeparatorLastChild})`,
    },
  },
  "&:last-of-type": {
    "&:before": {
      content: "''",
      display: "none",
      backgroundImage: "none",
    },
  },
});

const sideScroll = (element, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

function WidgetScheduleRow({
  alert,
  setAlert,
  machine,
  machineData,
  active,
  buildFiles,
  dialogFn,
  onClickMachine,
  putChangeBuildFilePosition,
  putUnscheduleBuildFile,
  isChangePositionLoading,
  putRescheduleToAnotherMachine,
  isRescheduleLoading,
  postBfmSwitchUnlockByMachineId,
  getBfmPrintCurrentStatusByMachineId,
}) {
  const overflowWrapper = useRef(null);
  const [items, setItems] = useState([]);
  const [status, setStatus] = useState({});
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(true);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(true);
  const [fadingItems, setFadingItems] = useState([]);
  const machineId = machine?.id;
  //const machineId = "mock";

  const updateItems = (newArray) => {
    const itemsToFade = newArray.filter(
      (newItem) => !items.some((item) => item.metadata.jobId === newItem.metadata.jobId)
    );
    const itemsToUnfade = items.filter(
      (item) => !newArray.some((newItem) => newItem.metadata.jobId === item.metadata.jobId)
    );

    if (itemsToFade.length > 0) {
      setFadingItems((prev) => [...prev, ...itemsToFade]);
      setTimeout(() => {
        setFadingItems((prev) => prev.filter((item) => !itemsToFade.includes(item)));
      }, 500);
    }

    if (itemsToUnfade.length > 0) {
      setFadingItems((prev) => [...prev, ...itemsToUnfade]);
      setTimeout(() => {
        setItems(newArray);
        setFadingItems((prev) => prev.filter((item) => !itemsToUnfade.includes(item)));
      }, 500);
    } else {
      setItems(newArray);
    }
  };

  useEffect(() => {
    const element = overflowWrapper.current;

    const checkForOverflow = () => {
      if (element) {
        const isOverflowing = element.scrollWidth > element.clientWidth;

        if (isOverflowing) {
          setIsLeftArrowDisabled(false);
          setIsRightArrowDisabled(false);
        } else {
          setIsLeftArrowDisabled(true);
          setIsRightArrowDisabled(true);
        }
      }
    };

    if (element) {
      window.addEventListener("resize", checkForOverflow);
      checkForOverflow();
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", checkForOverflow);
      }
    };
  }, [overflowWrapper.current]);

  useEffect(() => {
    updateItems(buildFiles);
  }, [buildFiles]);

  useEffect(() => {
    const fetchStatus = async () => {
      const response = await getBfmPrintCurrentStatusByMachineId(machineId);
      if (response && response.data && response.data.data) {
        const newStatus = response.data.data;
        setStatus((prevStatus) => {
          return JSON.stringify(prevStatus) !== JSON.stringify(newStatus) ? newStatus : prevStatus;
        });
      }
    };

    fetchStatus();

    let intervalId;
    intervalId = setInterval(fetchStatus, 15000);
    return () => clearInterval(intervalId);
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = ({ active, over }) => {
    if (!over || active.id === over.id) return;

    setItems((items) => {
      const oldIndex = items.findIndex((item) => item.metadata.jobId === active.id);
      const newIndex = items.findIndex((item) => item.metadata.jobId === over.id);

      const activeItem = items[oldIndex];
      const overItem = items[newIndex];

      const isActiveDraggable =
        activeItem.metadata.initiationStatus !== "PENDING" && activeItem.metadata.initiationStatus !== "STARTED";

      const isOverItemDraggable =
        overItem.metadata.initiationStatus !== "PENDING" && overItem.metadata.initiationStatus !== "STARTED";

      if (!isActiveDraggable) {
        return items;
      }

      if (!isOverItemDraggable) {
        return items;
      }

      if (oldIndex !== newIndex) {
        const activeBf = items.find((item) => item.metadata.jobId === active.id);
        putChangeBuildFilePosition(activeBf.id, active.id, machine.id, newIndex);
        return arrayMove(items, oldIndex, newIndex);
      }

      return items;
    });
  };

  const restrictToNoOverlapFirstElement = ({ transform, active, over }) => {
    if (!over || !active) return transform;

    const overIndex = over.data.current?.sortable?.index;
    const activeIndex = active.data.current?.sortable?.index;

    if (typeof activeIndex !== "number" || typeof overIndex !== "number") {
      return transform;
    }

    const firstItemElement = document.querySelector('[data-syklone="scheduling-build-file-wrapper"]:first-child');
    if (!firstItemElement) return transform;

    const firstItemRect = firstItemElement.getBoundingClientRect();

    const activeRect = active.rect.current.transformed;
    if (!activeRect || !firstItemRect) return transform;

    if (activeRect.left < firstItemRect.right && activeIndex !== 0) {
      return {
        ...transform,
        x: Math.max(transform.x, firstItemRect.right - activeRect.width),
        y: transform.y,
      };
    }

    return transform;
  };

  const getStyle = (item) => {
    return {
      opacity: fadingItems.some((fadeItem) => fadeItem.metadata.jobId === item.metadata.jobId) ? 0 : 1,
      transition: "opacity 500ms ease-in-out",
    };
  };

  return (
    <BoxContainer>
      <BoxBuildFilesWrapper>
        {items.length > 0 ? (
          <IconButton
            aria-label="scroll left"
            onClick={() => {
              sideScroll(overflowWrapper.current, 10, 200, -10);
            }}
            data-syklone="scheduling-arrow-left"
            disabled={isLeftArrowDisabled} // Disables left arrow
          >
            <icons.mui.ChevronLeft />
          </IconButton>
        ) : null}

        <BoxBuildFiles
          ref={overflowWrapper}
          sx={Array.isArray(items) && items.length === 0 ? { justifyContent: "center" } : {}}
        >
          {Array.isArray(items) && items.length > 0 ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToHorizontalAxis, restrictToNoOverlapFirstElement]}
            >
              <SortableContext
                items={items.map((item) => item.metadata?.jobId)}
                strategy={horizontalListSortingStrategy}
              >
                {items.map((item, index) => {
                  const { initiationStatus } = item.metadata;
                  const isDraggable = initiationStatus !== "PENDING" && initiationStatus !== "STARTED";

                  return (
                    <BoxWidgetScheduleRowItemWrapper
                      key={`${item.id}_${item.metadata.jobId}`}
                      data-syklone="scheduling-build-file-wrapper"
                      style={getStyle(item)}
                    >
                      <WidgetScheduleRowItem
                        alert={alert}
                        setAlert={setAlert}
                        data={{
                          ...item,
                          initiationStatus: initiationStatus,
                          lock: Array.isArray(items) && items.some((item) => item.metadata.lock),
                        }}
                        machineData={machineData}
                        id={item.id}
                        key={`${item.id}_${item.metadata.jobId}`}
                        isDraggable={isDraggable}
                        putUnscheduleBuildFile={putUnscheduleBuildFile}
                        machineValidity={item.machineValidity}
                        putRescheduleToAnotherMachine={putRescheduleToAnotherMachine}
                        isRescheduleLoading={isRescheduleLoading}
                      />
                    </BoxWidgetScheduleRowItemWrapper>
                  );
                })}
              </SortableContext>
            </DndContext>
          ) : (
            <Chip
              label={isChangePositionLoading?.machineId === machine.id ? "Loading..." : "No scheduled build files"}
              data-syklone="scheduling-empty"
            />
          )}
        </BoxBuildFiles>

        {items.length > 0 ? (
          <IconButton
            aria-label="scroll right"
            onClick={() => {
              sideScroll(overflowWrapper.current, 10, 200, 10);
            }}
            data-syklone="scheduling-arrow-right"
            disabled={isRightArrowDisabled} // Disables right arrow
          >
            <icons.mui.ChevronRight />
          </IconButton>
        ) : null}
      </BoxBuildFilesWrapper>
      <BoxPlayButton>
        <WidgetScheduleRowPlay
          active={active}
          dialogFn={dialogFn}
          buildFileData={items}
          machine={machine}
          machineStatus={status}
          isDisabled={
            !Array.isArray(items) ||
            items.length === 0 ||
            items.some((item) => item.metadata.lock) ||
            (items.length === 1 && items.some((item) => !item.metadata.isScheduled)) ||
            status["print_status"] === "ConnectionError"
          }
        />
      </BoxPlayButton>
      <BoxMachine>
        <WidgetScheduleRowItem
          data={{ ...machine, lock: Array.isArray(items) && items.some((item) => item.metadata.lock) }}
          isDraggable={false}
          width={470}
          onClickItem={onClickMachine}
          unlock={postBfmSwitchUnlockByMachineId}
          machineStatus={status}
        />
      </BoxMachine>
    </BoxContainer>
  );
}

WidgetScheduleRow.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  machine: PropTypes.object,
  machineData: PropTypes.array,
  active: PropTypes.bool,
  buildFiles: PropTypes.array,
  dialogFn: PropTypes.object,
  onClickMachine: PropTypes.func,
  putChangeBuildFilePosition: PropTypes.func,
  putUnscheduleBuildFile: PropTypes.func,
  isChangePositionLoading: PropTypes.any,
  putRescheduleToAnotherMachine: PropTypes.func,
  isRescheduleLoading: PropTypes.bool,
  postBfmSwitchLockByMachineId: PropTypes.func,
  postBfmSwitchUnlockByMachineId: PropTypes.func,
  getBfmPrintCurrentStatusByMachineId: PropTypes.func,
};

export default WidgetScheduleRow;
