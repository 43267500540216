import React, { useCallback } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, FormControl, Grid, icons, InputLabel, MenuItem, Select, Stack, Typography } from "syklone/ui/index.js";
import { useApiContext, usePermissionChecks } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { DialogToggleActivation, DialogUser, DialogElectronicSignature } from "../../dialogs/index.js";
import { WidgetCustomChip, WidgetTitle } from "../../widgets/index.js";
import { TableDataUsers } from "../../tables/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

function PageGlobalAdminSiteConfigurationUsers({ data, isLoading, handleRefreshData, error, optionsData }) {
  const apiInstance = useApiContext();
  const { showSnackbar } = useAlertSnackbar();
  const accountTypes = optionsData.accountTypes;
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  const [selectedAccountType, setSelectedAccountType] = React.useState("");
  const [selectedUserName, setSelectedUserName] = React.useState("");
  const dialogUserRef = React.useRef(null);
  const dialogActivationRef = React.useRef(null);
  const dialogElectronicSignatureRef = React.useRef(null);
  const selectedUserRef = React.useRef(null);
  const { checkElementVisibility } = usePermissionChecks();

  const setIsDialogOpenFn = (val) => {
    dialogUserRef.current?.setIsDialogOpen(val);
  };

  const setDialogEditDataFn = (val) => {
    dialogUserRef.current?.setDialogData(val);
  };

  const setDialogActivationDataFn = (data) => {
    setSelectedUserName(data.name);
    selectedUserRef.current = data;
    if (data.isActive) {
      dialogActivationRef.current?.setDialogData(data);
    }
  };

  const onClickCreateUserFn = () => {
    setIsDialogOpenFn(true);
    setDialogEditDataFn(null);
  };
  const handleChangeAccountType = (event) => {
    setSelectedAccountType(event.target.value);
  };

  const onClickReset = () => {
    setSelectedAccountType("");
  };

  const handleDialogSignatureOpen = (value) => {
    if (!value) {
      setSelectedUserName("");
    }
    if (!selectedUserRef.current) {
      return;
    }
    if (selectedUserRef.current?.isActive) {
      dialogActivationRef.current?.setIsDialogOpen(value);
    } else {
      dialogElectronicSignatureRef.current?.setIsDialogOpen(value);
    }
  };

  const setDialogResponseErrorMessage = (text) => {
    dialogUserRef.current?.setResponseMessage({ type: "error", text });
  };

  const setDialogActivationResponseErrorMessage = (text) => {
    dialogElectronicSignatureRef.current?.setResponseMessage({ type: "error", text });
  };

  const setDialogEditIsSubmitting = (value) => {
    dialogUserRef.current?.setIsSubmitting(value);
  };

  const setDialogActivationIsSubmitting = (value) => {
    if (!selectedUserRef.current) {
      return;
    }
    if (selectedUserRef.current.isActive) {
      dialogActivationRef.current?.setIsSubmitting(value);
    } else {
      dialogElectronicSignatureRef.current?.setIsSubmitting(value);
    }
  };

  const clearSelectedUser = () => {
    selectedUserRef.current = null;
  };

  const countStatus = useCallback(
    (isActive) => {
      let count = 0;
      if (data) {
        data.map((item) => {
          if (item.isActive === isActive) {
            count++;
          }
        });
      }
      return count;
    },
    [data]
  );

  const handleUserStatusChange = async (values) => {
    const id = selectedUserRef.current?.id;
    const isActiveUser = selectedUserRef.current.isActive;
    if (id) {
      try {
        setDialogActivationIsSubmitting(true);
        let response;
        if (isActiveUser) {
          response = await serviceAuth.postAdminAccountsDeactivateByAccountId(id);
        } else {
          if (values) {
            response = await serviceAuth.postAdminAccountsActivateByAccountId(id, values);
          } else {
            showSnackbar("Status was not successfully updated!", "error");
            handleDialogSignatureOpen(false);
          }
        }
        const responseMessage = response?.message ? response.message : "Status was successfully updated!";
        showSnackbar(responseMessage, "success");
        handleDialogSignatureOpen(false);
        handleRefreshData && handleRefreshData();
      } catch (e) {
        const message = typeof e?.detail === "string" ? e.detail : "Status was not successfully updated!";
        if (isActiveUser) {
          showSnackbar(message, "error");
          handleDialogSignatureOpen(false);
        } else {
          setDialogActivationResponseErrorMessage(message);
        }
      } finally {
        setDialogActivationIsSubmitting(false);
      }
    } else {
      showSnackbar("Status was not successfully updated!", "error");
      handleDialogSignatureOpen(false);
    }
  };

  const handleDialogUserEdit = async (id, payload) => {
    try {
      setDialogEditIsSubmitting(true);
      const response = await serviceAuth.putAdminAccountsByAccountId(id, payload);
      const responseMessage = response?.data?.message ? response.data.message : "User successfully updated";
      setIsDialogOpenFn(false);
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "User was not successfully updated!";
      setDialogResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  const handleDialogUserCreate = async (payload) => {
    try {
      setDialogEditIsSubmitting(true);
      const response = await serviceAuth.postAdminAccounts(payload);
      setIsDialogOpenFn(false);
      const responseMessage = response.message ? response.message : "User successfully created";
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "User was not successfully created!";
      setDialogResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  const dialogUserStatusChangeMessage = `Activate user ${selectedUserName}`;

  return (
    <>
      <Grid item xs>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <WidgetTitle title="Users" isBackButton={true} backRoute="/dashboard/admin" />
              </Grid>
              <Grid item>
                <Stack spacing={1} direction="row">
                  <Button
                    disabled={isLoading || !checkElementVisibility(PermissionVisualElements.adminUsersCreateUser)}
                    id="admin-create-user"
                    variant="contained"
                    startIcon={<icons.mui.Person />}
                    onClick={() => onClickCreateUserFn()}
                    data-syklone="admin-create-user-button"
                  >
                    Create User
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container spacing={3} data-syklone="admin-filter-wrapper">
                  <Grid item>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <FormControl sx={{ minWidth: 170 }} size="small" data-syklone="admin-filter-group">
                        <InputLabel id="admin-account-type-label">Account type</InputLabel>
                        <Select
                          labelId="admin-account-type-label"
                          id="admin-account-type"
                          value={selectedAccountType}
                          label="Account types"
                          name="types"
                          onChange={handleChangeAccountType}
                        >
                          {accountTypes.map((item) => {
                            return (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <Button
                        variant="outlined"
                        disabled={selectedAccountType.length > 0 ? false : true}
                        onClick={onClickReset}
                        data-syklone="admin-reset-filter-button"
                      >
                        Clear
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Typography sx={{ textTransform: "uppercase" }}>Users</Typography>
                      <WidgetCustomChip title="Active" type="success" val={countStatus(true)} />
                      <WidgetCustomChip title="Inactive" type="error" val={countStatus(false)} />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <TableDataUsers
                isLoading={isLoading}
                error={error}
                data={data}
                optionsData={optionsData}
                selected={{ accountType: selectedAccountType }}
                setters={{
                  setDialogEditDataFn: setDialogEditDataFn,
                  setIsDialogEditUserOpenFn: setIsDialogOpenFn,
                  setIsDialogDeleteUserOpenFn: handleDialogSignatureOpen,
                  setDialogActivationDataFn: setDialogActivationDataFn,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogUser
        ref={dialogUserRef}
        optionsData={optionsData}
        onDialogEditUser={handleDialogUserEdit}
        onDialogCreateUser={handleDialogUserCreate}
      />
      <DialogToggleActivation
        ref={dialogActivationRef}
        toggleActionName="user"
        onConfirm={() => handleUserStatusChange()}
        onDialogClose={clearSelectedUser}
      />
      <DialogElectronicSignature
        ref={dialogElectronicSignatureRef}
        subtitle={dialogUserStatusChangeMessage}
        onSubmitSuccess={handleUserStatusChange}
        onDialogClose={clearSelectedUser}
      />
    </>
  );
}

PageGlobalAdminSiteConfigurationUsers.propTypes = {
  data: PropTypes.array,
  optionsData: PropTypes.shape({
    accountTypes: PropTypes.array.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool,
  handleRefreshData: PropTypes.func,
  error: PropTypes.object,
};

export default PageGlobalAdminSiteConfigurationUsers;
