import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  TextField,
  Typography,
  Yup,
  useFormik,
} from "syklone/ui/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { DialogCommonWizardStepsAbove, DialogNotification } from "../../dialogs/index.js";
import { WidgetElectronicSignature, WidgetWizardActiveStepContainer } from "../index.js";

const faiCompleteItems = [
  {
    value: "1",
    label: "First option",
  },
  {
    value: "2",
    label: "Second option",
  },
  {
    value: "3",
    label: "Third option",
  },
];

const faiDocumentationtems = [
  {
    value: "1",
    label: "First option",
  },
  {
    value: "2",
    label: "Second option",
  },
  {
    value: "3",
    label: "Third option",
  },
];

const stepsTitle = ["Details", "Authenticate"];

const stepsCompleteStatus = {
  0: false,
  1: false,
};

const pendingDetailsStepSchema = Yup.object().shape({
  faiComplete: Yup.string().required("Required"),
  documentationPlm: Yup.string().required("Required"),
});

const pendingDetailsWithProductIdStepSchema = pendingDetailsStepSchema.shape({
  productId: Yup.string().required("Required"),
  productFamilyId: Yup.string().required("Required"),
});

const schemaArray = [[pendingDetailsStepSchema, pendingDetailsWithProductIdStepSchema], null];

const arePreviousStepsComplete = (stepsObject) => {
  return Object.values(stepsObject)
    .slice(0, -1)
    .every((v) => v);
};

const widgetName = "widget-pending-approval";

const WidgetPendingTitle = ({ approvalRequestor, productName, approvalStatus, approvalType }) => {
  return (
    <Box sx={{ margin: "16px 0px 8px 0px" }}>
      <Box>
        <Box sx={{ fontSize: 12, opacity: ".5" }}>
          Requestor: <span data-syklone={`${widgetName}-requestor`}>{approvalRequestor}</span> | Name:{" "}
          <span data-syklone={`${widgetName}-name`}>{productName}</span>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ fontWeight: 500, fontSize: 16 }} data-syklone={`${widgetName}-status`} data-id={approvalType}>
          {approvalStatus}
        </Typography>
      </Box>
    </Box>
  );
};

WidgetPendingTitle.propTypes = {
  approvalStatus: PropTypes.string,
  approvalType: PropTypes.number,
  approvalRequestor: PropTypes.string,
  productName: PropTypes.string,
};

//step 1
const WidgetPendingDetailsStep = ({
  hasApprovalType,
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
  productFamilies,
  setFieldTouched,
}) => {
  const apiInstance = useApiContext();
  const [autoCompleteValue, setAutocompleteValue] = useState("");
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");
  const allowedChars = "ADEHJKLMNPRTXY0123456789";
  const prohibitedChar = "Q";
  const controlKeys = ["BACKSPACE", "ARROWLEFT", "ARROWRIGHT", "DELETE", "TAB", "META"];

  const productIdValue = (val1, val2) => {
    if (val1 === null && val2 === null) {
      setAutocompleteValue("");
    } else if (val1 === null) {
      setAutocompleteValue(val2);
    } else {
      setAutocompleteValue(val1);
    }
  };

  useEffect(() => {
    setFieldValue("productId", autoCompleteValue);
  }, [autoCompleteValue, setFieldValue]);

  const getProducts = async (id) => {
    try {
      let response = await apiInstance.sykloneApi.bcGetProductFromFamily(id);
      if (response.data.data) {
        setProducts(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value.toUpperCase();
    const warnings = [];
    let isError = false;
    let errorMessage = "";

    for (let char of value) {
      if (char === prohibitedChar) {
        isError = true;
        errorMessage = `${char} is a prohibited character.`;
      } else if (!allowedChars.includes(char) && !controlKeys.includes(char)) {
        warnings.push(`${char} is not supported but allowed.`);
      }
    }

    if (isError) {
      setError({ message: errorMessage, isError: true, isWarning: false });
    } else if (warnings.length > 0) {
      setError({ message: warnings.join("\n"), isError: false, isWarning: true });
    } else {
      setError({ message: "", isError: false, isWarning: false });
    }

    productIdValue(null, value);
  };

  const handleKeyDown = (e) => {
    const key = e.key.toUpperCase();
    const controlKeys = ["BACKSPACE", "ARROWLEFT", "ARROWRIGHT", "DELETE", "TAB", "META", "CONTROL", "ALT", "SHIFT"];

    if (controlKeys.includes(key)) {
      return;
    }

    if (key === prohibitedChar) {
      setError({ message: `${key} is a prohibited character.`, isError: true, isWarning: false });
    } else if (!allowedChars.includes(key)) {
      setError({ message: `${key} is not supported but allowed.`, isError: false, isWarning: true });
    } else {
      setError({ message: "", isError: false, isWarning: false });
    }

    if (e.key === "Enter") {
      e.stopPropagation();
    }
  };

  return (
    <Box sx={{ minWidth: 396 }}>
      <form>
        <FormControl required sx={{ mb: 2 }} fullWidth error={touched.faiComplete && Boolean(errors.faiComplete)}>
          <InputLabel>FAI Complete</InputLabel>
          <Select name="faiComplete" value={values.faiComplete} label="FAI Complete" onChange={handleChange}>
            {faiCompleteItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          {touched.faiComplete && errors.faiComplete && <FormHelperText>{errors.faiComplete}</FormHelperText>}
        </FormControl>

        <FormControl
          required
          sx={{ mb: 2 }}
          fullWidth
          error={touched.documentationPlm && Boolean(errors.documentationPlm)}
        >
          <InputLabel>Documentation updated in PLM</InputLabel>
          <Select
            name="documentationPlm"
            value={values.documentationPlm}
            label="Documentation updated in PLM"
            onChange={handleChange}
          >
            {faiDocumentationtems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          {touched.documentationPlm && errors.documentationPlm && (
            <FormHelperText>{errors.documentationPlm}</FormHelperText>
          )}
        </FormControl>
        {hasApprovalType && (
          <>
            <FormControl fullWidth sx={{ mb: 1.5 }} data-syklone="product-families" required>
              <Autocomplete
                id="product-families-select"
                name="productFamilyId"
                options={productFamilies}
                getOptionLabel={(option) => option.title || ""}
                onChange={(_e, value) => {
                  setFieldValue("productFamilyId", value !== null ? value.id : "");
                }}
                sx={{ width: "100%" }}
                onBlur={() => setFieldTouched("productFamilyId")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Product families *"
                    error={touched.productFamilyId && Boolean(errors.productFamilyId)}
                  />
                )}
              />
              {touched.productFamilyId && errors.productFamilyId && (
                <FormHelperText error={Boolean(errors.productFamilyId)}>{errors.productFamilyId}</FormHelperText>
              )}
            </FormControl>
            {values.productFamilyId !== "" ? (
              <FormControl fullWidth sx={{ mb: 1.5 }} data-syklone="products" required>
                <Autocomplete
                  key={values.productFamilyId}
                  freeSolo
                  id="products-select"
                  name="productId"
                  onOpen={values.productFamilyId !== "" ? async () => await getProducts(values.productFamilyId) : null}
                  options={products}
                  getOptionLabel={(option) => option.product_id || ""}
                  onChange={(_e, value) => {
                    productIdValue(value !== null ? value.product_id : "", null);
                  }}
                  onBlur={() => setFieldTouched("productFamilyId")}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={handleInputChange}
                      label="Product ID *"
                      error={(touched.productId && Boolean(errors.productId)) || error.isError}
                      helperText={
                        error.message ||
                        "Please select an existing product ID or create a new one by typing the desired product ID."
                      }
                      FormHelperTextProps={{ style: { whiteSpace: "pre-line" } }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: handleKeyDown,
                        style: {
                          textTransform: "uppercase",
                          backgroundColor: error.isError
                            ? "rgba(255, 0, 0, 0.1)"
                            : error.isWarning
                            ? "rgba(255, 165, 0, 0.1)"
                            : "transparent",
                        },
                      }}
                    />
                  )}
                />
                {touched.productId && errors.productId && (
                  <FormHelperText error={Boolean(errors.productId)}>{errors.productId}</FormHelperText>
                )}
              </FormControl>
            ) : null}
          </>
        )}
      </form>
    </Box>
  );
};

WidgetPendingDetailsStep.propTypes = {
  hasApprovalType: PropTypes.bool,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  productFamilies: PropTypes.array,
};

const WidgetPendingApprovalDialog = React.forwardRef(
  ({ isOpen, onClose, data, onApprove, productFamilies, isSubmitting, savedData }, ref) => {
    if (!data) {
      return null;
    }
    const { type, approvalStatus, id, requestor } = data;
    const approvalRequestor = requestor;
    const productName = "";

    const [activeStep, setActiveStep] = React.useState(0);
    const widgetElectronicSignatureRef = React.useRef(null);
    const [messageStepText, setMessageStepText] = React.useState({ message: "", severity: "" });
    const approvalDialogSteps = React.useRef(stepsCompleteStatus);
    const hasApprovalType = type === 3;
    const isLastStep = stepsTitle.length === activeStep;

    React.useEffect(() => {
      if (isOpen) {
        setActiveStep(0);
        formik.resetForm();
        setMessageStepText({ message: "", severity: "" });
        approvalDialogSteps.current = stepsCompleteStatus;
      } else {
        widgetElectronicSignatureRef.current?.resetSignature();
      }
    }, [isOpen]);

    const nextStep = () => {
      approvalDialogSteps.current[activeStep] = true;
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    React.useImperativeHandle(
      ref,
      () => ({
        setStepMessage: (text, type) => setMessageStepText({ message: text, severity: type }),
        nextStep: () => nextStep(),
      }),
      []
    );

    const previousStep = () => {
      widgetElectronicSignatureRef.current?.resetSignature();
      setMessageStepText({ message: "", severity: "" });
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleApprove = () => {
      const canSubmitForm = arePreviousStepsComplete(approvalDialogSteps.current);

      if (!canSubmitForm) {
        setMessageStepText({ message: "Some of the steps are not completed", severity: "error" });
        return;
      }

      widgetElectronicSignatureRef.current?.setSignatureError(false);
      const { username, userSigned } = formik.values;
      if (!username || !userSigned) {
        widgetElectronicSignatureRef.current?.setSignatureError(true);
        return;
      }
      const values = {
        ...formik.values,
        type,
        id,
      };
      onApprove(values);
    };

    let handleNext;
    let buttonName;
    let validationSchema;
    switch (activeStep) {
      case 0:
        handleNext = nextStep;
        buttonName = "Next";
        validationSchema = hasApprovalType ? schemaArray[activeStep][1] : schemaArray[activeStep][0];
        break;
      case 1:
        handleNext = handleApprove;
        buttonName = "Approve";
        validationSchema = schemaArray[activeStep];
        break;
      default:
        handleNext = onClose;
        buttonName = "Close";
        validationSchema = null;
        break;
    }

    const formik = useFormik({
      initialValues: {
        faiComplete: "",
        documentationPlm: "",
        productId: "",
        productFamilyId: "",
        username: "",
        userSigned: false,
        userComment: "",
      },
      validationSchema: validationSchema,
      onSubmit: handleNext,
    });

    const { values, handleChange, errors, touched, setFieldValue, setFieldTouched } = formik;

    const onTabKeyPropagation = (event) => {
      if (event.key === "Tab") {
        event.stopPropagation();
      }
    };

    return (
      <DialogCommonWizardStepsAbove
        maxWidth={"xs"}
        dataTag={widgetName}
        stepsTitle={stepsTitle}
        nextStepTitle={buttonName}
        isOpen={isOpen}
        onClose={onClose}
        activeStep={activeStep}
        handleNext={() => formik.handleSubmit()}
        isNextDisabled={isSubmitting}
        handleBack={!isLastStep ? previousStep : () => undefined}
        isBackDisabled={isLastStep}
        onKeyDown={onTabKeyPropagation}
        showClose
        disableBackdropClick
      >
        <WidgetPendingTitle
          approvalRequestor={approvalRequestor}
          productName={productName}
          approvalType={type}
          approvalStatus={approvalStatus}
        />
        <DialogNotification alert={messageStepText} />
        <Box style={{ height: "12px" }} />
        <WidgetWizardActiveStepContainer activeStep={activeStep}>
          <WidgetPendingDetailsStep
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            hasApprovalType={hasApprovalType}
            productFamilies={productFamilies}
          />
          <WidgetElectronicSignature
            ref={widgetElectronicSignatureRef}
            values={values}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
          />
        </WidgetWizardActiveStepContainer>
        {!isLastStep && <Typography sx={{ fontSize: 12, opacity: ".5" }}>* required</Typography>}
      </DialogCommonWizardStepsAbove>
    );
  }
);

WidgetPendingApprovalDialog.displayName = "WidgetPendingApprovalDialog";

WidgetPendingApprovalDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.number,
    requestor: PropTypes.string,
    contentTypeId: PropTypes.number,
    approvalStatus: PropTypes.string,
  }).isRequired,
  onApprove: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  productFamilies: PropTypes.array,
  savedData: PropTypes.object,
};

export default WidgetPendingApprovalDialog;
