import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, styled, Typography } from "syklone/ui/index.js";

const Container = styled(({ fade, isbuilding, ...rest }) => <Box {...rest} />)(({ fade, isbuilding }) => {
  return {
    backgroundColor: isbuilding ? "#121212" : "#3c3c3c",
    padding: "0rem 0.5rem 0.5rem 0.5rem",
    borderRadius: "3px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    opacity: fade ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };
});

const BoxTitle = styled(Typography)({
  opacity: 0.7,
});

const BoxEmpty = styled(Typography)({
  fontSize: "12px",
  display: "flex",
  border: "2px dotted #A0A0A0",
  borderRadius: "5px",
  padding: "0.1rem",
  alignItems: "center",
  justifyContent: "center",
});

function WidgetScheduleCurrentBuildEmpty(props) {
  return <BoxEmpty>Machine is not building</BoxEmpty>;
}

WidgetScheduleCurrentBuildEmpty.propTypes = {};

function WidgetScheduleCurrentBuild({ data }) {
  /* eslint-disable camelcase */
  const { bfm_id, job_id } = data ?? {};
  const isBuilding = Boolean(bfm_id && job_id);

  const [fade, setFade] = useState(false);

  useEffect(() => {
    setFade(false);
    const timeoutId = setTimeout(() => {
      setFade(true);
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [isBuilding]);

  return (
    <Container fade={fade} isbuilding={isBuilding}>
      <BoxTitle variant="overline">Current build</BoxTitle>

      {isBuilding ? (
        <ul style={{ fontSize: "11px", padding: 0, margin: 0, listStyle: "none", width: "107%" }}>
          <li>Build file ID: {bfm_id}</li>
          <li>Job ID: {job_id}</li>
        </ul>
      ) : (
        <WidgetScheduleCurrentBuildEmpty />
      )}
    </Container>
  );
}

WidgetScheduleCurrentBuild.propTypes = {
  data: PropTypes.object,
};

export default WidgetScheduleCurrentBuild;
