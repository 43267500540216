import React from "react";
import PropTypes from "prop-types";
import { Grid, styled, Typography } from "syklone/ui/index.js";

const inputStyle = {
  color: "inherit",
  borderRadius: "6px",
  border: "none",
  outline: "none",
  fontSize: "14px",
  padding: "10px",
  marginRight: "8px",
  width: "36px",
  textAlign: "center",
};

const CustomInput = styled(({ iserror, iswarning, ...rest }) => <input {...rest} />)(
  ({ theme, iserror, iswarning }) => {
    return {
      backgroundColor: iserror ? "rgba(255, 0, 0, 0.1)" : iswarning ? "rgba(255, 165, 0, 0.1)" : "rgba(67, 67, 67)",
      ...inputStyle,
      borderColor: iserror ? "red" : iswarning ? "orange" : "transparent",
    };
  }
);

const CustomInputDisabled = styled("input")(() => ({
  backgroundColor: "rgba(67, 67, 67, 0.5)",
  ...inputStyle,
}));

const ErrorMessage = styled(({ iserror, iswarning, ...rest }) => <Typography {...rest} />)(
  ({ theme, iserror, iswarning }) => {
    return {
      color: iserror ? "red" : iswarning ? "orange" : "transparent",
      marginTop: "4px",
    };
  }
);

function WidgetLabelInput({ parsedSchema, inputValues, inputErrors, onInputChange }) {
  let fields = parsedSchema.map((field) => ({
    type: field.actionType,
    count: field.actionLength,
    keyword: field.actionParameter,
    title: field.actionType === "fixed" ? "Fixed" : convertToHumanReadable(field.actionParameter),
    value:
      field.actionType === "fixed"
        ? field.actionParameter
        : `{${field.actionType}: ${field.actionLength}, ${field.actionParameter}}`,
  }));

  function convertToHumanReadable(keyword) {
    const capitalLetterMappings = {
      PRINTORDER: "Print order",
      PLATID: "Platform ID",
      PARTID: "Part ID",
      LOTID: "Lot ID",
      LASERID: "Laser ID",
      PRODID: "Product ID",
    };

    return capitalLetterMappings[keyword?.toUpperCase()] || keyword;
  }

  const handleInputChange = (event, fieldIndex, charIndex) => {
    const newInputValues = [...inputValues];
    const newInputErrors = [...inputErrors];

    const inputChar = event.target.value.toUpperCase();
    const allowedChars = "ADEHJKLMNPRTXY0123456789";
    const prohibitedChar = "Q";

    if (inputChar === prohibitedChar) {
      newInputErrors[fieldIndex][charIndex] = {
        message: `${inputChar} is a prohibited character.`,
        isError: true,
        isWarning: false,
      };
      newInputValues[fieldIndex][charIndex] = "";
    } else {
      if (!allowedChars.includes(inputChar)) {
        newInputErrors[fieldIndex][charIndex] = {
          message: `${inputChar} is not a recommended character but is allowed.`,
          isError: false,
          isWarning: true,
        };
      } else {
        newInputErrors[fieldIndex][charIndex] = { message: "", isError: false, isWarning: false };
      }
      newInputValues[fieldIndex][charIndex] = inputChar;
    }

    onInputChange(newInputValues, newInputErrors);
  };

  return (
    <Grid container spacing={2} alignItems="flex-start" sx={{ display: "flex", flexWrap: "nowrap" }}>
      {fields.map((field, fieldIndex) => (
        <Grid item key={fieldIndex}>
          <Grid container direction="column" sx={{ position: "relative" }}>
            <Grid item>
              <Typography variant="overline" display="block">
                {field.title}
              </Typography>
            </Grid>
            <Grid item sx={{ display: "flex", flexWrap: "nowrap" }}>
              {field.type === "static"
                ? field.value
                    .split("")
                    .map((char, charIndex) => (
                      <CustomInputDisabled
                        key={charIndex}
                        type="text"
                        maxLength={1}
                        value={char}
                        disabled
                        autoComplete="off"
                      />
                    ))
                : field.type === "fixed"
                ? field.value
                    .split("")
                    .map((char, charIndex) => (
                      <CustomInputDisabled
                        key={charIndex}
                        type="text"
                        maxLength={1}
                        value={char}
                        disabled
                        autoComplete="off"
                      />
                    ))
                : field.type === "auto" || field.type === "dyn" || field.type === "ctx"
                ? inputValues[fieldIndex].map((value, charIndex) => (
                    <CustomInputDisabled
                      key={charIndex}
                      type="text"
                      maxLength={1}
                      value={field.type === "auto" || field.type === "ctx" ? value.toString() : ""}
                      disabled
                      autoComplete="off"
                    />
                  ))
                : inputValues[fieldIndex].map((value, charIndex) => (
                    <CustomInput
                      key={charIndex}
                      type="text"
                      maxLength={1}
                      value={value.toString()}
                      onChange={(event) => handleInputChange(event, fieldIndex, charIndex)}
                      autoComplete="off"
                      iserror={inputErrors[fieldIndex][charIndex]?.isError}
                      iswarning={inputErrors[fieldIndex][charIndex]?.isWarning}
                    />
                  ))}
            </Grid>
            <Grid item>
              {inputErrors[fieldIndex].some((error) => error.message) && (
                <div>
                  {inputErrors[fieldIndex].map((error, idx) =>
                    error.message ? (
                      <ErrorMessage key={idx} iserror={error.isError} iswarning={error.isWarning}>
                        {error.message}
                      </ErrorMessage>
                    ) : null
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

WidgetLabelInput.propTypes = {
  parsedSchema: PropTypes.arrayOf(
    PropTypes.shape({
      actionType: PropTypes.string,
      actionLength: PropTypes.number,
      actionParameter: PropTypes.string,
    })
  ),
  inputValues: PropTypes.array,
  inputErrors: PropTypes.array,
  onInputChange: PropTypes.func.isRequired,
};

export default WidgetLabelInput;
