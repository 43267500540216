import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { motion } from "framer-motion";

// imports: local
import * as img from "../../img/index.js";
import { Box, Grid, Typography, icons, keyframes } from "syklone/ui/index.js";

// imports: local
import { WidgetAuthButtons, WidgetAccessFeature, WidgetSuiteVersion } from "../../widgets/index.js";

const transitionImg = { duration: 1.7, ease: [0.33, 1, 0.68, 1] };
const transition = { duration: 0.4, ease: [0.33, 1, 0.68, 1] };
const underlineAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

function PageGfLandingAccess() {
  const [pages, active, setActive] = useOutletContext();

  useEffect(() => {
    setActive(pages[2].id);
  });

  const content = [
    {
      id: 1,
      title: "Access to create part and platform",
      description: "Available with general access",
    },
    {
      id: 2,
      title: "Access to product specific parameter sets",
      description: "Request programmer role through user account settings",
    },
    {
      id: 3,
      title: "Access to product approval groups",
      description: "Request product approval role through user account setting",
    },
    {
      id: 4,
      title: "Administrator access",
      description: "Request admin role through user account settings",
    },
  ];

  return (
    <motion.div initial="initial" animate="animate" exit="exit">
      <Box
        component={motion.div}
        initial={{
          opacity: 1,
          position: "absolute",
          right: "auto",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        animate={{ transform: "translate(0%, 0%)", left: "-25%", top: "13%" }}
        transition={transitionImg}
      >
        <motion.img
          style={styles.sphere}
          initial={{ scale: 1, opacity: 1, rotate: 0 }}
          animate={{ scale: 1.4, opacity: 1, rotate: -90 }}
          transition={transitionImg}
          src={img.Sphere}
          alt="Sphere"
        />
      </Box>
      <Box>
        <Grid
          container
          direction="column"
          spacing={3}
          sx={(theme) => ({
            maxWidth: "800px!important",
            zIndex: 5,
            position: "relative",
            textAlign: "left",
            [theme.breakpoints.down("md")]: { padding: "0px 20px" },
          })}
        >
          <Grid item>
            <motion.ul
              style={styles.ctrlList}
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, ...transition }}
            >
              {pages.map((item) => {
                return (
                  <Box
                    component="li"
                    sx={{
                      ...styles.ctrlListItem,
                      ...(active === item.id ? styles.active : styles.readMore),
                    }}
                    key={item.id}
                  >
                    <Link to={item.to} onClick={() => setActive(item.id)} id={item.id}>
                      {item.title}
                    </Link>
                  </Box>
                );
              })}
            </motion.ul>
          </Grid>
          <Grid
            item
            component={motion.div}
            initial={{ y: -5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, ...transition }}
          >
            <Typography
              component="h1"
              variant="h3"
              sx={(theme) => ({
                fontWeight: 500,
                [theme.breakpoints.down("sm")]: { fontSize: "1.6rem" },
              })}
            >
              Access and support
            </Typography>
          </Grid>
          <Grid
            item
            component={motion.div}
            initial={{ y: 5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.8, ...transition }}
          >
            <Grid container direction="column" spacing={2} sx={{ padding: "20px 0px" }}>
              {content.map((item, index) => (
                <WidgetAccessFeature key={index} content={item} index={index} />
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            component={motion.div}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.9, ...transition }}
            sx={styles.readMore}
          >
            <Link to="/">
              Read more
              <icons.mui.ArrowForward sx={{ color: "#ffb500" }} fontSize="small" />
            </Link>
          </Grid>
          <Grid
            item
            component={motion.div}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, ...transition }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "230px" }}>
              <WidgetAuthButtons />
            </Box>
            <WidgetSuiteVersion containerStyle={{ marginTop: "15px" }} preText="Version: " />
          </Grid>
        </Grid>
      </Box>
    </motion.div>
  );
}

const styles = {
  readMore: {
    "& > a": {
      display: "inline-flex",
      alignItems: "center",
      gap: "3px",
      fontSize: 14,
      backgroundImage: "linear-gradient(to right, #feb400, #feb400 50%, #fff 50%)",
      backgroundSize: "200% 100%",
      backgroundPosition: "-100%",
      padding: "5px 0px",
      position: "relative",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      transition: "all 0.3s ease-in-out",
    },
    "& > a::before": {
      content: "''",
      background: "#feb400",
      display: "block",
      position: "absolute",
      bottom: "-3px",
      left: "0px",
      width: "0px",
      height: "2px",
      transition: "all 0.3s ease-in-out",
    },
    "& > a:hover": {
      backgroundPosition: 0,
    },
    "& > a:hover::before": {
      width: "100%",
    },
  },
  mainTitleUnderline: {
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: -2,
      height: 2,
      width: "0px",
      backgroundColor: "#ffb500",
      animation: `${underlineAnimation} 300ms forwards ease-in-out`,
      animationDelay: "500ms",
    },
  },
  "@keyframes underline": {
    "0%": {
      width: 0,
    },
    "100%": {
      width: "100%",
    },
  },
  ctrlList: {
    display: "flex",
    listStyle: "none",
    gap: "15px",
    padding: 0,
    margin: 0,
  },
  ctrlListItem: {},
  active: {
    "& > a": {
      display: "inline-flex",
      alignItems: "center",
      gap: "3px",
      fontSize: 14,
      padding: "5px 0px",
      color: "#fff",
      textDecoration: "none",
    },
    border: "1px solid #fff",
    borderRadius: "20px",
    padding: "0px 10px",
  },
};

export default PageGfLandingAccess;
